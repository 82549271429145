import './FrequentlyAskedQuestions.scss';
import './Licensing.scss';

import React from 'react';
import {
  Link,
  Router,
  Redirect,
  RouteComponentProps,
  WindowLocation,
} from '@reach/router';

import * as Core from 'components/core';

interface Props extends RouteComponentProps {}

const Licensing: React.FC<Props> = () => {
  return (
    <Core.DocumentTitle title="Licensing">
      <Core.ContentWrapper narrow>
        <div>
          <Link to="/licensing/desktop" getProps={licensingLinkProps}>
            Desktop EULA
          </Link>
          <Link to="/licensing/web" getProps={licensingLinkProps}>
            Web Font EULA
          </Link>
          <Link to="/licensing/mobile" getProps={licensingLinkProps}>
            Mobile App EULA
          </Link>

          {window.ENV.trialFontsAvailable && (
            <Link to="/licensing/trial" getProps={licensingLinkProps}>
              Trial Font EULA
            </Link>
          )}
        </div>

        <hr />

        <Router primary={false}>
          <Redirect noThrow from="/" to="/licensing/desktop" />
          <License path=":type" />
        </Router>
      </Core.ContentWrapper>
    </Core.DocumentTitle>
  );
};

interface LicenseProps extends RouteComponentProps {
  type?: string;
}

const License: React.FC<LicenseProps> = (props) => {
  return <Core.PageContent slug={`licensing/${props.type}`} />;
};

const licensingLinkProps = ({
  href,
  location,
}: {
  href: string;
  location: WindowLocation;
}) => {
  return href === location.pathname
    ? { className: 'Licensing-navLink is-active' }
    : { className: 'Licensing-navLink' };
};

export default Licensing;
