import { State } from './index';

export type Invert = {
  type: 'COLORS/INVERT';
  payload: { inverted: boolean };
};

export const actionType = 'COLORS/INVERT';

export function action(inverted: boolean): Invert {
  return {
    type: actionType,
    payload: { inverted },
  };
}

export function reducer(state: State, action: Invert) {
  return {
    ...state,
    inverted: action.payload.inverted,
  };
}

// @ts-ignore
export function* saga(action: Invert) {
  if (document.body != null) {
    if (action.payload.inverted) {
      document.body.classList.add('inverted');
    } else {
      document.body.classList.remove('inverted');
    }
  }
}
