import React from 'react';
import { RouteComponentProps } from '@reach/router';

import * as Core from 'components/core';

interface Props extends RouteComponentProps {}

const TermsOfUse: React.FC<Props> = () => {
  return (
    <Core.ContentWrapper narrow>
      <Core.PageContent slug="terms" />
    </Core.ContentWrapper>
  );
};

export default TermsOfUse;
