import update from 'immutability-helper';

import { State } from './index';

export type HighlightFamily = {
  type: 'FONT_LICENSE/HIGHLIGHT_FAMILY';
  payload: { highlighted: boolean };
};

export const actionType = 'FONT_LICENSE/HIGHLIGHT_FAMILY';

export function action(highlighted: boolean): HighlightFamily {
  return {
    type: actionType,
    payload: { highlighted },
  };
}

export function reducer(state: State, action: HighlightFamily) {
  const { highlighted } = action.payload;

  return update(state, {
    license: { familyHighlighted: { $set: highlighted } },
  });
}
