import ErrorBoundaryMessage from './ErrorBoundaryMessage';

import * as React from 'react';
import * as Sentry from '@sentry/react';

const ErrorBoundary: React.FC<{
  children: React.ReactNode;
}> = (props) => {
  const { children } = props;

  return (
    <Sentry.ErrorBoundary fallback={<ErrorBoundaryMessage />}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
