import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';

import * as Core from 'components/core';
import All from './All';
import WorkInProgress from './WorkInProgress';

const Single = React.lazy(() => import('./Single'));

interface Props extends RouteComponentProps {}

const Families: React.FC<Props> = () => {
  return (
    <React.Suspense fallback={<Core.Loader />}>
      <Router primary={false}>
        <WorkInProgress path="/work-in-progress" />
        <All path="/" />
        <Single path=":slug" />
      </Router>
    </React.Suspense>
  );
};

export default Families;
