export function set(path: string) {
  try {
    sessionStorage.setItem('redirectTo', path);
  } catch (error) {
    // Fail silently if sessionStorage cannot be accessed
  }
}

export function get(defaultPath: string): string {
  let path = defaultPath;

  try {
    path = sessionStorage.getItem('redirectTo') || defaultPath;
    sessionStorage.removeItem('redirectTo');
  } catch (error) {
    // Fail silently if sessionStorage cannot be accessed
  }

  return path;
}
