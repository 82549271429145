import { uniqueId } from 'lodash-es';
import { put, delay } from 'redux-saga/effects';

import { State } from './index';
import { action as removeAction } from './remove';

export type Append = {
  type: 'NOTIFICATIONS/APPEND';
  payload: { id: string; content: string };
};

export const actionType = 'NOTIFICATIONS/APPEND';

export function action(content: string): Append {
  return {
    type: actionType,
    payload: { id: uniqueId('notification'), content },
  };
}

export function reducer(state: State, action: Append) {
  return [...state, action.payload];
}

export function* saga(action: Append) {
  yield delay(3000);
  yield put(removeAction(action.payload.id));
}
