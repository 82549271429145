import './LargeTextIntro.scss';

import React from 'react';
import classNames from 'classnames';

interface Props {
  children: React.ReactNode;
  isInverted?: boolean;
  reducedPadding?: boolean;
}

const LargeTextIntro: React.FC<Props> = (props) => {
  const { children, isInverted = false, reducedPadding = false } = props;
  return (
    <div
      className={classNames('LargeTextIntro', {
        'LargeTextIntro is-inverted': isInverted,
        'LargeTextIntro is-reducedPadding': reducedPadding,
      })}
    >
      {children}
    </div>
  );
};

export default LargeTextIntro;
