import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';

import * as Core from 'components/core';
import Single from './Single';

const All = React.lazy(() => import('./All'));

interface Props extends RouteComponentProps {}

const Blog: React.FC<Props> = () => {
  return (
    <Core.DocumentTitle title="Blog">
      <Core.ContentWrapper>
        <React.Suspense fallback={<Core.Loader />}>
          <Router primary={false}>
            <All path="/" />
            <Single path=":slug" />
          </Router>
        </React.Suspense>
      </Core.ContentWrapper>
    </Core.DocumentTitle>
  );
};

export default Blog;
