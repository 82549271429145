import React from 'react';
import { RouteComponentProps } from '@reach/router';

import * as Core from 'components/core';

interface Props extends RouteComponentProps {}

const PagesPrivacyPolicy: React.FC<Props> = () => {
  return (
    <Core.ContentWrapper narrow>
      <Core.PageContent slug="privacy" />
    </Core.ContentWrapper>
  );
};

export default PagesPrivacyPolicy;
