import { connect } from 'react-redux';
import { takeEvery } from 'redux-saga/effects';

import * as invert from './invert';
import { Invert } from './invert';
import * as setColors from './setColors';
import { SetColors } from './setColors';
import { State as ModulesState } from 'modules';

export interface State {
  inverted: boolean;
  foregroundColor?: string;
  logoBackgroundColor?: string;
}

type Action = Invert | SetColors;

export const actions = {
  invert: invert.action,
  setColors: setColors.action,
};

export function reducer(
  state: State = { inverted: false },
  action: Action,
): State {
  switch (action.type) {
    case 'COLORS/INVERT':
      return invert.reducer(state, action);
    case 'COLORS/SET':
      return setColors.reducer(state, action);
  }

  return state;
}

export function* saga() {
  yield takeEvery(invert.actionType, invert.saga);
}

export const Container = connect((state: ModulesState) => {
  return {
    inverted: state.colors.inverted,
    foregroundColor:
      state.colors.foregroundColor ?? state.fontLicense.family?.foregroundColor,
    logoBackgroundColor:
      state.colors.foregroundColor ??
      state.fontLicense.family?.logoBackgroundColor,
  };
})((({ inverted, foregroundColor, logoBackgroundColor, children }) =>
  children({ inverted, foregroundColor, logoBackgroundColor })) as React.FC<{
  inverted: boolean;
  foregroundColor: string | undefined;
  logoBackgroundColor: string | undefined;
  children(options: {
    inverted: boolean;
    foregroundColor: string | undefined;
    logoBackgroundColor: string | undefined;
  }): React.ReactNode;
}>);
