import React from 'react';
import { Link, RouteComponentProps } from '@reach/router';

import * as Core from 'components/core';

interface Props extends RouteComponentProps {}

const TrialsConfirmation: React.FC<Props> = () => {
  return (
    <Core.ContentWrapper>
      <p>
        Thanks! You will receive an email with download instructions shortly.
      </p>
      <p>
        <Link to="/">Back to home page</Link>
      </p>
    </Core.ContentWrapper>
  );
};

export default TrialsConfirmation;
