import './WorkInProgress.scss';

import React from 'react';

import { Query } from 'react-apollo';
import { partition, sortBy } from 'lodash-es';

import * as Core from 'components/core';
import { FONT_FAMILIES_QUERY } from './fontFamiliesQuery';
import { FontFamiliesQuery } from 'client/graphql/types/operations';
import { RouteComponentProps } from '@reach/router';

import Preview from './Preview';
import FamiliesLink from './FamiliesLink';

interface Props extends RouteComponentProps {}

const FamiliesWIP: React.FC<Props> = () => {
  return (
    <div className="FamiliesWIP">
      <Query<FontFamiliesQuery> query={FONT_FAMILIES_QUERY}>
        {({ loading, data }) => {
          if (loading) {
            return <Core.Loader />;
          }

          if (data == null) {
            return null;
          }

          const [families, additionalFamilies] = partition(
            sortBy(data.families, ['position']),
            (family) => family.status === 'wip',
          );

          return (
            <>
              <div className="FamiliesWIP-heading">
                <Core.SecondaryHeading>
                  Work in Progress Font Families
                </Core.SecondaryHeading>
              </div>

              <div className="FamiliesWIP-list">
                <FamiliesLink
                  families={additionalFamilies}
                  link="/families"
                  buttonText="&larr; View All Retail Fonts"
                />

                {families.map((family) => (
                  <Preview key={family.id} family={family} />
                ))}

                {families.length % 2 === 0 && (
                  <div className="FamiliesWIP-familyPlaceholder" />
                )}
              </div>
            </>
          );
        }}
      </Query>
    </div>
  );
};

export default FamiliesWIP;
