import React from 'react';
import { RouteComponentProps } from '@reach/router';

import * as Core from 'components/core';
import RegistrationForm from 'components/shared/RegistrationForm';

interface Props extends RouteComponentProps {}

const Registration: React.FC<Props> = () => {
  return (
    <Core.DocumentTitle title="Sign Up">
      <Core.ContentWrapper>
        <RegistrationForm />
      </Core.ContentWrapper>
    </Core.DocumentTitle>
  );
};

export default Registration;
