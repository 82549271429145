import React from 'react';
import { Query } from 'react-apollo';
import { Link, Redirect, RouteComponentProps } from '@reach/router';

import * as Core from 'components/core';
import { RegistrationForm } from 'components/shared';

import {
  CurrentOrderQuery,
  CURRENT_ORDER_QUERY,
} from 'components/queries/CurrentOrderQuery';

import {
  CurrentUserQuery,
  CURRENT_USER_QUERY,
} from 'components/queries/CurrentUserQuery';

interface Props extends RouteComponentProps {}

const CheckoutRegistration: React.FC<Props> = () => {
  return (
    <Query<CurrentOrderQuery> query={CURRENT_ORDER_QUERY}>
      {({ data, loading }) => {
        if (loading) {
          return <Core.Loader />;
        }

        const redirectTo =
          (
            (data != null &&
              data.currentOrder != null &&
              data.currentOrder.fontLicenses) ||
            []
          ).length > 0
            ? '/checkout/licensing'
            : '/checkout/payment';

        return (
          <Core.ContentWrapper>
            <Query<CurrentUserQuery> query={CURRENT_USER_QUERY}>
              {({ data, loading }) => {
                if (loading) {
                  return <Core.Loader />;
                }

                if (data != null && data.currentUser != null) {
                  return <Redirect noThrow to={redirectTo} />;
                }

                return (
                  <>
                    <h2 className="heading-2">Sign Up to Complete Purchase</h2>

                    <RegistrationForm redirectTo={redirectTo} />

                    <div>
                      Already have an account?{' '}
                      <Link
                        to={`/session?redirectTo=${encodeURIComponent(
                          redirectTo,
                        )}`}
                      >
                        Sign In
                      </Link>
                    </div>
                  </>
                );
              }}
            </Query>
          </Core.ContentWrapper>
        );
      }}
    </Query>
  );
};

export default CheckoutRegistration;
