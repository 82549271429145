import { State } from './index';

export type Remove = {
  type: 'NOTIFICATIONS/REMOVE';
  payload: { id: string };
};

export const actionType = 'NOTIFICATIONS/REMOVE';

export function action(id: string): Remove {
  return {
    type: actionType,
    payload: { id },
  };
}

export function reducer(state: State, action: Remove) {
  return state.filter((notification) => notification.id !== action.payload.id);
}
