import './Link.scss';

import React from 'react';
import classNames from 'classnames';
import { Link as ReachRouterLink, LinkProps } from '@reach/router';

interface Props extends Omit<LinkProps<Record<string, unknown>>, 'ref'> {
  button?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
}

const Link: React.FC<Props> = (props) => {
  const { button, fullWidth, disabled, children, ...linkProps } = props;

  return (
    <ReachRouterLink
      className={classNames('Link', {
        'Link--button': button,
        'Link--fullWidth': fullWidth,
        'is-disabled': disabled,
      })}
      {...linkProps}
    >
      {children}
    </ReachRouterLink>
  );
};

export default Link;
