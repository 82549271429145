import './FamiliesLink.scss';

import React, { useCallback, useMemo, useState } from 'react';
import { Link } from '@reach/router';

import { FontFamiliesQuery } from 'client/graphql/types/operations';

interface Props {
  families: FontFamiliesQuery['families'];
  link: string;
  buttonText: string;
}

const FamiliesLink: React.FC<Props> = (props) => {
  const { families, link, buttonText } = props;

  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHovered(false);
  }, []);

  const truncatedFamilies = useMemo(() => {
    if (families.length > 9) {
      return families.slice(0, 8);
    }
    return families;
  }, [families]);

  const additionalFamilies = families.length - truncatedFamilies.length;

  let tileCount = truncatedFamilies.length;

  const placeholderTiles: JSX.Element[] = [];

  while (tileCount < 3) {
    tileCount++;
    placeholderTiles.push(<div className="FamiliesLink-placeholder" />);
  }

  while (tileCount > 3 && tileCount < 6) {
    tileCount++;
    placeholderTiles.push(<div className="FamiliesLink-placeholder" />);
  }

  if (tileCount == 7) {
    placeholderTiles.push(<div className="FamiliesLink-placeholder" />);
  }

  return (
    <Link
      to={link}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="FamiliesLink"
    >
      <div className="FamiliesLink-tiles">
        {truncatedFamilies.map((family) => (
          <div
            className="FamiliesLink-tile"
            key={family.id}
            style={hovered ? { backgroundColor: `#${family.accentColor}` } : {}}
          />
        ))}

        {placeholderTiles}

        {additionalFamilies > 0 && (
          <div
            className="FamiliesLink-tile FamiliesLink-moreFamilies"
            style={hovered ? { backgroundColor: `#CCCCCC` } : {}}
          >
            {additionalFamilies} More...
          </div>
        )}
      </div>
      <div className="FamiliesLink-labelContainer">
        <div className="FamiliesLink-label">
          <div>{buttonText}</div>
        </div>
      </div>
    </Link>
  );
};

export default FamiliesLink;
