import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';

import * as Core from 'components/core';
import Information from './Information';
import Request from './Request';
import Confirmation from './Confirmation';

interface Props extends RouteComponentProps {}

const Trials: React.FC<Props> = () => {
  return (
    <Core.DocumentTitle title="Trial Fonts">
      <Router basepath="/trial-fonts">
        <Information path="/" />
        <Request path="request" />
        <Confirmation path="confirmation" />
      </Router>
    </Core.DocumentTitle>
  );
};

export default Trials;
