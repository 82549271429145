import './PreviewTable.scss';

import React from 'react';
import { uniq } from 'lodash-es';
import classNames from 'classnames';

import { sortFontFaces } from 'utils/sorting';
import { FontFamiliesQuery } from 'client/graphql/types/operations';

type Face = FontFamiliesQuery['families'][number]['faces'][number];

interface Props {
  faces: Face[];
  activeFace: Face;
}

const FamiliesPreviewTable: React.FC<Props> = (props) => {
  const { activeFace } = props;

  const faces = sortFontFaces(props.faces);

  const variants = uniq(faces.map(({ variant }) => variant));
  const sizes = uniq(faces.map(({ size }) => size));
  const widths = uniq(faces.map(({ width }) => width));
  const weights = uniq(faces.map(({ weight }) => weight));

  return (
    <div className="PreviewTable">
      {variants.map((variant) => (
        <div key={variant} className="PreviewTable-variant">
          {sizes.map((size) => {
            if (
              !faces.some(
                (face) => face.variant === variant && face.size === size,
              )
            ) {
              return null;
            }

            return (
              <div key={size} className="PreviewTable-size">
                {widths.map((width) => {
                  if (
                    !faces.some(
                      (face) =>
                        face.variant === variant &&
                        face.size === size &&
                        face.width === width,
                    )
                  ) {
                    return null;
                  }

                  return (
                    <div key={width} className="PreviewTable-width">
                      {weights.map((weight) => {
                        if (
                          !faces.some(
                            (face) =>
                              face.variant === variant &&
                              face.weight === weight,
                          )
                        ) {
                          return null;
                        }

                        const romanFace = findFace(faces, {
                          variant,
                          size,
                          width,
                          weight,
                          style: 'roman',
                        });

                        const italicFace = findFace(faces, {
                          variant,
                          size,
                          width,
                          weight,
                          style: 'italic',
                        });

                        const solidFace = findFace(faces, {
                          variant,
                          size,
                          width,
                          weight,
                          style: 'solid',
                        });

                        const inlineFace = findFace(faces, {
                          variant,
                          size,
                          width,
                          weight,
                          style: 'inline',
                        });

                        const engravedFace = findFace(faces, {
                          variant,
                          size,
                          width,
                          weight,
                          style: 'engraved',
                        });

                        if (
                          romanFace == null &&
                          italicFace == null &&
                          inlineFace == null &&
                          engravedFace == null &&
                          solidFace == null
                        ) {
                          return (
                            <div
                              key={weight}
                              className="PreviewTable-missing-width"
                            />
                          );
                        }

                        return (
                          <div key={weight} className="PreviewTable-weight">
                            {romanFace != null && (
                              <div
                                className={classNames('PreviewTable-style', {
                                  active: romanFace === activeFace,
                                })}
                              />
                            )}

                            {italicFace != null && (
                              <div
                                className={classNames('PreviewTable-style', {
                                  active: italicFace === activeFace,
                                })}
                              />
                            )}

                            {solidFace != null && (
                              <div
                                className={classNames('PreviewTable-style', {
                                  active: solidFace === activeFace,
                                })}
                              />
                            )}

                            {engravedFace != null && (
                              <div
                                className={classNames('PreviewTable-style', {
                                  active: engravedFace === activeFace,
                                })}
                              />
                            )}

                            {inlineFace != null && (
                              <div
                                className={classNames('PreviewTable-style', {
                                  active: inlineFace === activeFace,
                                })}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

const findFace = (
  faces: Face[],
  options: {
    variant: string;
    size: string;
    width: string;
    weight: string;
    style: string;
  },
): Face | undefined => {
  return faces.find(
    (face) =>
      face.variant === options.variant &&
      face.size === options.size &&
      face.width === options.width &&
      face.weight === options.weight &&
      face.style === options.style,
  );
};

export default FamiliesPreviewTable;
