import gql from 'graphql-tag';

export const FONT_LICENSE_ATTRIBUTES = gql`
  fragment FontLicenseAttributes on FontLicense {
    id
    total

    option {
      id
      kind
    }

    family {
      id
      name
    }

    faces {
      id
      description
    }
  }
`;

export const LINE_ITEM_ATTRIBUTES = gql`
  fragment LineItemAttributes on LineItem {
    id
    quantity
    total

    product {
      id
      name

      images {
        id

        url {
          small
        }
      }
    }
  }
`;

export const CURRENT_ORDER_QUERY = gql`
  query CurrentOrderQuery {
    currentOrder {
      id
      state
      upgradeDescription
      total
      subtotal

      fontLicenses {
        ...FontLicenseAttributes
      }

      lineItems {
        ...LineItemAttributes
      }
    }
  }

  ${FONT_LICENSE_ATTRIBUTES}
  ${LINE_ITEM_ATTRIBUTES}
`;

import { CurrentOrderQuery } from 'client/graphql/types/operations';

export type { CurrentOrderQuery };
