import './Information.scss';

import React from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { sortBy } from 'lodash-es';

import * as Core from 'components/core';
import Family from './Family';

import { TrialArchivesFamiliesQuery } from 'client/graphql/types/operations';
import { TrialsGraphic } from './TrialsGraphic';

const TRIAL_ARCHIVES_FAMILIES = gql`
  query TrialArchivesFamiliesQuery {
    families {
      id
      name
      slug

      trialArchives {
        id
        name
        description
        fontsCount
        position
      }
    }
  }
`;

interface Props extends RouteComponentProps {}

const TrialsInformation: React.FC<Props> = () => {
  return (
    <Query<TrialArchivesFamiliesQuery> query={TRIAL_ARCHIVES_FAMILIES}>
      {({ data, loading }) => {
        if (loading) {
          return <Core.Loader />;
        }

        if (data == null) {
          throw new Error('Expected "data" to not be nullish');
        }

        return (
          <>
            <Core.ContentWrapper>
              <Core.LargeTextIntro reducedPadding>
                <div className="Trials-intro">
                  We offer free Trial versions of all our retail fonts, for use
                  in mockups and proposals. Download for free and try out any
                  family in your draft designs.
                </div>
              </Core.LargeTextIntro>
            </Core.ContentWrapper>

            <Core.ContentWrapper narrow inline>
              <p>
                Fonts are supplied in Desktop (OTF) format only, with “TRIAL”
                appended to the family name. Trial fonts contain a minimal
                character set, designed to help you evaluate whether our fonts
                are suitable for your needs before purchasing:
              </p>
              <p className="Trails-characterSet">
                A B C D E F G H I J K L M N O P Q R S T U V W X&nbsp;Y&nbsp;Z
                <br />a b c d e f g h i j k l m n o p q r s t u v w
                x&nbsp;y&nbsp;z
                <br />0 1 2 3 4 5 6 7 8 9&nbsp;.&nbsp;,
              </p>
              <p>
                To preserve copyfit between the trial and full versions, glyphs
                that have been removed are replaced with a placeholder of the
                same width. Please note that ligature substitution and
                contextual alternates are not active in trial fonts.
              </p>

              <div className="Trials-image">
                <TrialsGraphic />
              </div>

              <p>
                Complete retail versions of our fonts must be licensed for any
                commercial, live or public-facing uses. You can download our
                Trial Fonts for your personal use, to test on your personal
                devices. You can also download our Trial Fonts on behalf of your
                organization, for testing by up to five users within your
                organization. If there are additional users within your
                organization who need to test the Trial Fonts, contact us for an
                extended trial license.
              </p>

              <p>
                Please review our{' '}
                <Link to="/licensing/trial">Trial License</Link> terms
                carefully, as you are agreeing to these terms when you download,
                install or use our Trial Fonts. If you’re unsure whether your
                intended use is permitted,{' '}
                <a href="mailto:licensing@frerejones.com">please contact us</a>.
              </p>
            </Core.ContentWrapper>

            <Core.ContentWrapper inline>
              <div className="Trials-families">
                {sortBy(
                  data.families.filter(
                    (family) => family.trialArchives.length > 0,
                  ),
                  'name',
                ).map((family) => (
                  <Family key={family.id} family={family} />
                ))}
              </div>
            </Core.ContentWrapper>
          </>
        );
      }}
    </Query>
  );
};

export default TrialsInformation;
