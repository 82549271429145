import update from 'immutability-helper';

import { LicenseType } from 'types';
import { State } from './index';

export const actionType = 'FONT_LICENSE/SELECT_LICENSE_OPTION';

export type SelectLicenseOption = {
  type: 'FONT_LICENSE/SELECT_LICENSE_OPTION';
  payload: {
    licenseType: LicenseType;
    licenseOptionId: number | null;
  };
};

export function action(
  licenseType: LicenseType,
  licenseOptionId: number | null,
): SelectLicenseOption {
  return {
    type: actionType,
    payload: {
      licenseType,
      licenseOptionId,
    },
  };
}

export function reducer(state: State, action: SelectLicenseOption) {
  const { licenseType, licenseOptionId } = action.payload;

  return update(state, {
    license: { licenseOptionIds: { [licenseType]: { $set: licenseOptionId } } },
  });
}
