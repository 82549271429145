import './Request.scss';

import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { RouteComponentProps, Redirect } from '@reach/router';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import {
  CurrentUserQuery,
  CURRENT_USER_QUERY,
} from 'components/queries/CurrentUserQuery';
import CountriesContainer from 'components/shared/CountriesContainer';
import {
  CreateFontTrialMutation,
  CreateFontTrialMutationVariables,
} from 'client/graphql/types/operations';
import { FontTrialUsage } from 'client/graphql/types/operations';

import * as Core from 'components/core';

const CREATE_FONT_TRIAL_MUTATION = gql`
  mutation CreateFontTrialMutation($input: CreateFontTrialInput!) {
    createFontTrial(input: $input) {
      fontTrial {
        id
      }

      error
    }
  }
`;

interface Props extends RouteComponentProps {}

const TrialRequest: React.FC<Props> = () => {
  const fontTrialArchiveIds = useMemo(() => {
    return parseIDs(location.search.slice(1));
  }, []);

  if (fontTrialArchiveIds == null || fontTrialArchiveIds.length === 0) {
    return <Redirect to="/trial-fonts" />;
  }

  return (
    <Query<CurrentUserQuery> query={CURRENT_USER_QUERY}>
      {({ data, loading: currentUserLoading }) => {
        if (!currentUserLoading && data?.currentUser == null) {
          const sessionRedirectTo = `${location.pathname}${location.search}`;

          return (
            <Redirect
              to={`/session?redirectTo=${encodeURIComponent(
                sessionRedirectTo,
              )}`}
            />
          );
        }

        return (
          <CountriesContainer>
            {(countries) => {
              if (currentUserLoading) {
                return <Core.Loader />;
              }

              return (
                <Mutation<
                  CreateFontTrialMutation,
                  CreateFontTrialMutationVariables
                >
                  mutation={CREATE_FONT_TRIAL_MUTATION}
                >
                  {(
                    createFontTrial,
                    { loading, data: createFontTrialData },
                  ) => (
                    <Core.ContentWrapper>
                      <h1 className="heading-1">Trial Licensee Information</h1>

                      {createFontTrialData?.createFontTrial?.fontTrial?.id !=
                      null ? (
                        <Redirect to="/trial-fonts/confirmation" />
                      ) : (
                        <Formik
                          initialValues={{
                            usage: FontTrialUsage.Personal,
                            companyName: '',
                            address: {
                              name: data?.currentUser?.name || '',
                              address1: '',
                              address2: null,
                              locality: '',
                              region: '',
                              country: '',
                              postalCode: '',
                            },
                            agreedToLicense: false,
                            subscribeToNewsletter: true,
                          }}
                          onSubmit={({
                            usage,
                            companyName,
                            address,
                            subscribeToNewsletter,
                          }) => {
                            createFontTrial({
                              variables: {
                                input: {
                                  fontTrialArchiveIds,
                                  usage,
                                  companyName:
                                    usage === FontTrialUsage.Professional
                                      ? companyName
                                      : null,
                                  address,
                                  subscribeToNewsletter,
                                },
                              },
                            });
                          }}
                        >
                          {({
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <Core.InputFormGroup
                                name="address.name"
                                label="Your Name"
                                autoComplete="name"
                                value={values.address.name}
                                disabled={loading}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                              <Core.FormGroup label="Licensing for yourself or on behalf of an organization?">
                                {() => (
                                  <div className="Request-radioWrapper">
                                    <label className="Request-radioLabel">
                                      <input
                                        className="Request-radioButton"
                                        type="radio"
                                        name="usage"
                                        value={FontTrialUsage.Personal}
                                        checked={
                                          values.usage ===
                                          FontTrialUsage.Personal
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />{' '}
                                      Personal use
                                    </label>

                                    <label className="Request-radioLabel">
                                      <input
                                        className="Request-radioButton"
                                        type="radio"
                                        name="usage"
                                        value={FontTrialUsage.Professional}
                                        checked={
                                          values.usage ===
                                          FontTrialUsage.Professional
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />{' '}
                                      Organization
                                    </label>
                                  </div>
                                )}
                              </Core.FormGroup>

                              {values.usage === FontTrialUsage.Professional && (
                                <Core.InputFormGroup
                                  name="companyName"
                                  label="Organization Name"
                                  value={values.companyName}
                                  required
                                  disabled={loading}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              )}

                              <Core.InputFormGroup
                                name="address.address1"
                                label="Address 1"
                                autoComplete="address-line1"
                                value={values.address.address1}
                                required
                                disabled={loading}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                              <Core.InputFormGroup
                                name="address.address2"
                                label="Address 2"
                                autoComplete="address-line2"
                                value={values.address.address2 || ''}
                                disabled={loading}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                              <Core.InputFormGroup
                                name="address.locality"
                                label="City"
                                autoComplete="address-level2"
                                value={values.address.locality}
                                required
                                disabled={loading}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                              <Core.InputFormGroup
                                name="address.region"
                                label="State/Province/Region"
                                autoComplete="address-level1"
                                value={values.address.region}
                                disabled={loading}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                              <Core.FormGroup label="Country">
                                {({ inputId }) => (
                                  <Core.Select
                                    id={inputId}
                                    name="address.country"
                                    autoComplete="country-name"
                                    required
                                    value={values.address.country}
                                    disabled={loading}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    <option />

                                    {countries.map((country) => (
                                      <option
                                        key={country.alpha2}
                                        value={country.alpha2}
                                      >
                                        {country.name}
                                      </option>
                                    ))}
                                  </Core.Select>
                                )}
                              </Core.FormGroup>

                              <Core.InputFormGroup
                                name="address.postalCode"
                                label="Zip/Postal Code"
                                autoComplete="postal-code"
                                required
                                disabled={loading}
                                value={values.address.postalCode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                              <div className="TrialRequest-terms">
                                <p className="TrialRequest-terms-explanation">
                                  Frere-Jones Type Trial Fonts may be used
                                  solely for evaluation and testing purposes.
                                  They contain a minimal character set, designed
                                  to help you evaluate whether our fonts are
                                  suitable for your needs before purchasing.
                                  Please review our Trial License terms
                                  carefully, as you are agreeing to these terms
                                  when you download, install or use our Trial
                                  Fonts.
                                </p>

                                <Core.CheckboxField
                                  name="agreedToLicense"
                                  checked={values.agreedToLicense}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  I have read and agree to the terms of the
                                  Frere-Jones Type{' '}
                                  <a href="/licensing/trial" target="_blank">
                                    Trial Font License Agreement
                                  </a>
                                  .
                                </Core.CheckboxField>

                                <Core.CheckboxField
                                  name="subscribeToNewsletter"
                                  checked={values.subscribeToNewsletter}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  Sign up for our newsletter
                                </Core.CheckboxField>

                                <div className="Request-submit">
                                  <Core.Button
                                    type="submit"
                                    primary
                                    disabled={!values.agreedToLicense}
                                  >
                                    Get Trial Fonts
                                  </Core.Button>
                                </div>

                                <p className="TrialRequest-terms-account">
                                  A download link will be emailed to{' '}
                                  {data?.currentUser?.email}.{' '}
                                  <a href="/account" target="_blank">
                                    Update Email Address
                                  </a>
                                </p>
                              </div>
                            </form>
                          )}
                        </Formik>
                      )}
                    </Core.ContentWrapper>
                  )}
                </Mutation>
              );
            }}
          </CountriesContainer>
        );
      }}
    </Query>
  );
};

export default TrialRequest;

function parseIDs(queryString: string) {
  const ids = new URLSearchParams(queryString).get('trialArchiveIds');

  const splitIDs = (value: string) => {
    return value
      .split(',')
      .map((id) => parseInt(id))
      .filter((id) => !Number.isNaN(id));
  };

  if (typeof ids === 'string') {
    return splitIDs(ids);
  }

  if (Array.isArray(ids) && typeof ids[0] === 'string') {
    return splitIDs(ids[0]);
  }

  return null;
}
