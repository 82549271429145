import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import * as FontLicense from 'modules/font-license';
import * as Notifications from 'modules/notifications';
import * as Colors from 'modules/colors';

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  fontLicense: FontLicense.reducer,
  notifications: Notifications.reducer,
  colors: Colors.reducer,
});

function* rootSaga() {
  yield all([FontLicense.saga(), Notifications.saga(), Colors.saga()]);
}

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(rootSaga);

export default store;
