import './SecondaryHeading.scss';

import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const SecondaryHeading: React.FC<Props> = (props) => {
  const { children } = props;

  return <h3 className="CoreSecondaryHeading">{children}</h3>;
};

export default SecondaryHeading;
