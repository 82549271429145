import React from 'react';

export const TrialsGraphic: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1487.2 570.4">
      <rect width="1487.2" height="570.4" fill="#fff" />
      <g>
        <path d="m14.24,17.08l5.78-11.82h3.18v14.56h-1.94V6.86l-6.58,13.18h-.92L7.16,6.86v12.96h-1.88V5.26h3.18l5.78,11.82Z" />
        <path d="m35.06,5.16h2.22l6.7,14.66h-2.16l-1.66-3.72h-8.22l-1.7,3.72h-2.06l6.88-14.66Zm4.36,9.22l-3.32-7.36-3.38,7.36h6.7Z" />
        <path d="m48.98,5.26h1.98v12.76h7.12v1.8h-9.1V5.26Z" />
        <path d="m63.18,5.26h1.98v12.76h7.12v1.8h-9.1V5.26Z" />
        <path d="m83.66,20.12c-4.36,0-7.66-3.34-7.66-7.56s3.36-7.6,7.7-7.6,7.64,3.34,7.64,7.56-3.36,7.6-7.68,7.6Zm-.04-13.32c-3.06,0-5.6,2.48-5.6,5.72s2.5,5.76,5.64,5.76,5.66-2.48,5.66-5.72-2.56-5.76-5.7-5.76Z" />
        <path d="m101.02,13.98h-2.18v5.84h-1.98V5.26h5.18c2.8,0,4.94,1.48,4.94,4.3,0,2.14-1.56,3.72-3.7,4.2l5.8,6.06h-2.56l-5.5-5.84Zm-2.2-6.98v5.28h3.06c1.76,0,3.1-1.08,3.1-2.68s-1.28-2.6-3.02-2.6h-3.14Z" />
        <path d="m118.62,13.3v6.52h-1.98v-6.46l-5.76-8.1h2.36l4.42,6.34,4.4-6.34h2.28l-5.72,8.04Z" />
        <path d="m134.8,12.54c0-4.32,3.46-7.52,7.6-7.52,2.2,0,3.78.72,4.96,1.56v2.28h-.04c-1.38-1.12-2.92-1.98-4.98-1.98-2.96,0-5.52,2.46-5.52,5.62,0,3.48,2.66,5.72,5.46,5.72,2,0,3.76-.8,5.3-2.04l.04.04-.3,2.28c-1.34.88-2.94,1.56-5.22,1.56-3.94,0-7.3-2.94-7.3-7.52Z" />
        <path d="m159.78,20.12c-4.36,0-7.66-3.34-7.66-7.56s3.36-7.6,7.7-7.6,7.64,3.34,7.64,7.56-3.36,7.6-7.68,7.6Zm-.04-13.32c-3.06,0-5.6,2.48-5.6,5.72s2.5,5.76,5.64,5.76,5.66-2.48,5.66-5.72-2.56-5.76-5.7-5.76Z" />
        <path d="m181.94,17.08l5.78-11.82h3.18v14.56h-1.94V6.86l-6.58,13.18h-.92l-6.6-13.18v12.96h-1.88V5.26h3.18l5.78,11.82Z" />
        <path d="m199.44,14.52v5.3h-1.98V5.26h4.88c3.02,0,5.24,1.54,5.24,4.52s-2.3,4.74-5.38,4.74h-2.76Zm0-7.5v5.74h2.76c2,0,3.38-1.1,3.38-2.96,0-1.7-1.3-2.78-3.3-2.78h-2.84Z" />
        <path d="m216.94,5.16h2.22l6.7,14.66h-2.16l-1.66-3.72h-8.22l-1.7,3.72h-2.06l6.88-14.66Zm4.36,9.22l-3.32-7.36-3.38,7.36h6.7Z" />
        <path d="m229.2,12.54c0-4.32,3.46-7.52,7.6-7.52,2.2,0,3.78.72,4.96,1.56v2.28h-.04c-1.38-1.12-2.92-1.98-4.98-1.98-2.96,0-5.52,2.46-5.52,5.62,0,3.48,2.66,5.72,5.46,5.72,2,0,3.76-.8,5.3-2.04l.04.04-.3,2.28c-1.34.88-2.94,1.56-5.22,1.56-3.94,0-7.3-2.94-7.3-7.52Z" />
        <path d="m253.82,7.04v12.78h-1.98V7.04h-5.76v-1.78h13.52v1.78h-5.78Z" />
        <path d="m276.9,19.82h-5.48V5.26h5.46c2.84,0,4.62,1.42,4.62,3.8,0,1.46-.88,2.56-2.3,3.18,1.8.54,2.96,1.72,2.96,3.5,0,2.52-2.2,4.08-5.26,4.08Zm-3.56-8.24h3.06c1.9,0,3.14-1,3.14-2.38s-1.1-2.24-2.82-2.24h-3.38v4.62Zm3.6,6.54c2.04,0,3.24-1.04,3.24-2.46,0-1.56-1.36-2.44-3.48-2.44h-3.36v4.9h3.6Z" />
        <path d="m294.26,20.12c-4.36,0-7.66-3.34-7.66-7.56s3.36-7.6,7.7-7.6,7.64,3.34,7.64,7.56-3.36,7.6-7.68,7.6Zm-.04-13.32c-3.06,0-5.6,2.48-5.6,5.72s2.5,5.76,5.64,5.76,5.66-2.48,5.66-5.72-2.56-5.76-5.7-5.76Z" />
        <path d="m307.46,5.26h1.98v12.76h7.12v1.8h-9.1V5.26Z" />
        <path d="m321.66,5.26h4.84c5.06,0,8.32,2.76,8.32,7.24s-3.54,7.32-8.32,7.32h-4.84V5.26Zm1.98,1.78v11h2.9c3.52,0,6.24-2.04,6.24-5.5s-2.62-5.5-6.32-5.5h-2.82Z" />
      </g>
      <path d="m44.27,128.08H6.61l-.7-5.72,20.33-26.05c4.31-5.37,5.9-8.27,5.9-11.79,0-3.78-2.55-6.86-6.86-6.86-5.72,0-11.18,4.4-16.81,10.91l-.18-.09-1.14-13.73c4.66-5.28,11.09-9.24,20.15-9.24,11.44,0,18.13,7.13,18.13,16.98,0,6.78-2.82,11.97-9.15,19.45l-11.18,14.61,23.14-.18-3.96,11.7Z" />
      <g>
        <path d="m69.35,97.46v30.62h-13.02v-44.09l12.67-1.67v8.45c4.49-6.25,8.89-8.8,14.7-8.8,8.18,0,13.9,5.9,13.9,16.54v29.57h-13.02v-27.02c0-4.75-2.29-7.66-6.6-7.66-2.82,0-5.1.97-8.62,4.05Z" />
        <path d="m136.23,122.01c-3.96,4.58-8.01,6.95-13.46,6.95-9.94,0-17.78-9.15-17.78-22.53,0-14.87,9.15-24.2,19.98-24.2,4.14,0,7.74,1.58,11,4.22v-21.03l13.02-3.52v66.17h-12.76v-6.07Zm-8.8-3.96c2.9,0,5.46-.97,8.54-3.17v-19.54c-2.82-1.58-5.19-2.29-7.83-2.29-5.98,0-10.38,4.75-10.38,12.58s4.22,12.41,9.68,12.41Z" />
        <path d="m197.39,66.31h14.96l23.67,61.77h-14.87l-4.49-12.76h-24.64l-4.66,12.76h-14.26l24.29-61.77Zm15.58,37.84l-8.54-23.5-8.62,23.5h17.16Z" />
        <path d="m263.39,128.96h-9.59l-18.83-46.02h13.99l10.03,28.16,10.47-28.16h13.64l-19.71,46.02Z" />
        <path d="m307.74,129.05c-14.08,0-22.62-9.42-22.62-23.58,0-12.94,8.45-23.76,21.38-23.76,13.73,0,20.15,11,20.15,23.58,0,1.06-.18,2.99-.26,3.96h-28.69c1.32,6.34,6.07,9.5,12.5,9.5,5.37,0,10.56-1.85,15.31-4.49l.18.18-3.43,11.18c-4.31,2.11-8.36,3.43-14.52,3.43Zm-10.03-27.37h16.72c-.44-6.42-3.34-10.47-8.1-10.47s-8.01,4.14-8.62,10.47Z" />
        <path d="m347.6,97.46v30.62h-13.02v-44.09l12.67-1.67v8.45c4.49-6.25,8.89-8.8,14.7-8.8,8.18,0,13.9,5.9,13.9,16.54v29.57h-13.02v-27.02c0-4.75-2.29-7.66-6.6-7.66-2.82,0-5.1.97-8.62,4.05Z" />
        <path d="m413.78,120.25c-4.49,6.16-8.89,8.71-14.61,8.71-8.27,0-13.99-5.9-13.99-16.46v-29.57h13.02v27.02c0,4.75,2.29,7.57,6.6,7.57,2.82,0,5.19-.97,8.62-3.96v-30.62h13.02v45.14h-12.67v-7.83Z" />
        <path d="m456.98,129.05c-14.08,0-22.62-9.42-22.62-23.58,0-12.94,8.45-23.76,21.38-23.76,13.73,0,20.15,11,20.15,23.58,0,1.06-.18,2.99-.26,3.96h-28.69c1.32,6.34,6.07,9.5,12.5,9.5,5.37,0,10.56-1.85,15.31-4.49l.18.18-3.43,11.18c-4.31,2.11-8.36,3.43-14.52,3.43Zm-10.03-27.37h16.72c-.44-6.42-3.34-10.47-8.1-10.47s-8.01,4.14-8.62,10.47Z" />
        <path d="m508.2,76.08c-4.49,0-7.83-3.17-7.83-7.39s3.34-7.39,7.83-7.39,7.74,3.17,7.74,7.39-3.34,7.39-7.74,7.39Zm-6.51,6.86h13.02v45.14h-13.02v-45.14Z" />
        <path d="m538.56,97.46v30.62h-13.02v-44.09l12.67-1.67v8.45c4.49-6.25,8.89-8.8,14.7-8.8,8.18,0,13.9,5.9,13.9,16.54v29.57h-13.02v-27.02c0-4.75-2.29-7.66-6.6-7.66-2.82,0-5.1.97-8.62,4.05Z" />
        <path d="m595.23,66.48h36.43l2.29,11.88h-25.08v13.11h20.86v10.91h-20.86v13.82h25.25l-2.2,11.88h-36.69v-61.6Z" />
        <path d="m667.92,122.1c-4.93,4.49-9.68,6.78-14.96,6.78-7.22,0-12.41-4.58-12.41-12.14,0-8.54,6.69-14.7,27.01-15.4-.26-5.9-2.99-8.54-8.36-8.54-4.31,0-9.24,1.76-14.34,5.72l-.26-.18,1.94-12.06c3.87-2.38,8.89-4.22,15.49-4.22,11.88,0,18.48,6.34,18.48,19.1v25.87l-12.58,1.67v-6.6Zm-10.38-2.38c2.55,0,5.72-.97,10.03-3.34v-8.71c-12.85,1.23-14.96,4.66-14.96,7.74,0,2.73,1.85,4.31,4.93,4.31Z" />
        <path d="m705.75,129.05c-6.6,0-11.88-1.76-16.63-4.75l-1.67-12.23.18-.09c5.63,4.14,12.32,7.3,18.57,7.3,3.61,0,5.81-1.41,5.81-3.52,0-2.29-1.67-3.43-8.89-5.63-8.71-2.82-14.7-6.16-14.7-14.34s6.78-13.82,17.95-13.82c6.16,0,11.09,1.76,14.78,3.87v11.26h-.18c-4.4-2.9-10.12-5.46-14.96-5.46-3.61,0-5.54,1.41-5.54,3.34,0,2.2,1.94,3.26,8.54,5.19,9.68,3.08,15.05,6.51,15.05,14.87s-7.3,13.99-18.3,13.99Z" />
        <path d="m748.7,129.05c-8.89,0-14.26-5.1-14.26-16.19v-19.71h-6.16v-10.21h6.16v-9.59l12.85-3.26v12.85h14.17v10.21h-14.17v18.57c0,4.4,2.29,6.25,6.07,6.25,2.9,0,5.37-1.23,8.1-3.26l.18.09-1.85,11.26c-2.9,1.85-6.78,2.99-11.09,2.99Z" />
        <path d="m827.19,103.09h-25.25v24.99h-13.82v-61.6h13.82v24.02h25.25v-24.02h13.82v61.6h-13.82v-24.99Z" />
        <path d="m877.53,122.1c-4.93,4.49-9.68,6.78-14.96,6.78-7.22,0-12.41-4.58-12.41-12.14,0-8.54,6.69-14.7,27.01-15.4-.26-5.9-2.99-8.54-8.36-8.54-4.31,0-9.24,1.76-14.34,5.72l-.26-.18,1.94-12.06c3.87-2.38,8.89-4.22,15.49-4.22,11.88,0,18.48,6.34,18.48,19.1v25.87l-12.58,1.67v-6.6Zm-10.38-2.38c2.55,0,5.72-.97,10.03-3.34v-8.71c-12.85,1.23-14.96,4.66-14.96,7.74,0,2.73,1.85,4.31,4.93,4.31Z" />
        <path d="m912.82,101.42v26.66h-13.02v-44.09l12.67-1.67v11.7c5.54-8.71,8.98-11.88,13.64-11.88.97,0,1.58.09,2.64.35l.7,13.82c-.88-.09-1.76-.18-2.99-.18-4.66,0-8.98,1.85-13.64,5.28Z" />
        <path d="m936.66,65.43l13.02-3.52v66.17h-13.02v-62.65Z" />
        <path d="m980.49,129.05c-14.08,0-22.62-9.42-22.62-23.58,0-12.94,8.45-23.76,21.38-23.76,13.73,0,20.15,11,20.15,23.58,0,1.06-.18,2.99-.26,3.96h-28.69c1.32,6.34,6.07,9.5,12.5,9.5,5.37,0,10.56-1.85,15.31-4.49l.18.18-3.43,11.18c-4.31,2.11-8.36,3.43-14.52,3.43Zm-10.03-27.37h16.72c-.44-6.42-3.34-10.47-8.1-10.47s-8.01,4.14-8.62,10.47Z" />
        <path d="m1020.35,128.08h-13.02v-44.09l12.67-1.67v7.92c4.31-5.81,8.45-8.27,13.9-8.27s10.21,3.08,12.32,8.71c4.49-6.16,8.8-8.71,14.61-8.71,8.1,0,13.64,5.9,13.64,16.37v29.74h-13.02v-27.1c0-4.75-2.11-7.57-6.25-7.57-2.64,0-4.75.88-7.74,3.61v31.06h-13.02v-27.1c0-4.75-2.2-7.57-6.34-7.57-2.55,0-4.66.88-7.74,3.61v31.06Z" />
        <path d="m1114.95,97.46v30.62h-13.02v-62.65l13.02-3.52v28.33c4.4-5.81,8.62-8.27,14.34-8.27,8.18,0,13.9,5.9,13.9,16.54v29.57h-13.02v-27.02c0-4.75-2.29-7.66-6.6-7.66-2.82,0-5.19.97-8.62,4.05Z" />
        <path d="m1178.13,122.1c-4.93,4.49-9.68,6.78-14.96,6.78-7.22,0-12.41-4.58-12.41-12.14,0-8.54,6.69-14.7,27.01-15.4-.26-5.9-2.99-8.54-8.36-8.54-4.31,0-9.24,1.76-14.34,5.72l-.26-.18,1.94-12.06c3.87-2.38,8.89-4.22,15.49-4.22,11.88,0,18.48,6.34,18.48,19.1v25.87l-12.58,1.67v-6.6Zm-10.38-2.38c2.55,0,5.72-.97,10.03-3.34v-8.71c-12.85,1.23-14.96,4.66-14.96,7.74,0,2.73,1.85,4.31,4.93,4.31Z" />
        <path d="m1215.97,129.05c-6.6,0-11.88-1.76-16.63-4.75l-1.67-12.23.18-.09c5.63,4.14,12.32,7.3,18.57,7.3,3.61,0,5.81-1.41,5.81-3.52,0-2.29-1.67-3.43-8.89-5.63-8.71-2.82-14.7-6.16-14.7-14.34s6.78-13.82,17.95-13.82c6.16,0,11.09,1.76,14.78,3.87v11.26h-.18c-4.4-2.9-10.12-5.46-14.96-5.46-3.61,0-5.54,1.41-5.54,3.34,0,2.2,1.94,3.26,8.54,5.19,9.68,3.08,15.05,6.51,15.05,14.87s-7.3,13.99-18.3,13.99Z" />
        <path d="m1280.12,128.79c-3.96,0-7.57-.88-11.26-2.29l-8.18,1.76h-.97v-62.83l13.02-3.52v26.4c3.87-4.14,7.83-6.25,12.94-6.25,10.12,0,17.69,8.98,17.69,22.35,0,15.14-10.47,24.38-23.23,24.38Zm-7.39-33.09v21.21c2.11.79,4.14,1.14,6.42,1.14,6.6,0,11.44-4.84,11.44-12.94s-4.05-12.14-9.68-12.14c-2.82,0-5.28.79-8.18,2.73Z" />
        <path d="m1331.33,129.05c-14.08,0-22.62-9.42-22.62-23.58,0-12.94,8.45-23.76,21.38-23.76,13.73,0,20.15,11,20.15,23.58,0,1.06-.18,2.99-.26,3.96h-28.69c1.32,6.34,6.07,9.5,12.5,9.5,5.37,0,10.56-1.85,15.31-4.49l.18.18-3.43,11.18c-4.31,2.11-8.36,3.43-14.52,3.43Zm-10.03-27.37h16.72c-.44-6.42-3.34-10.47-8.1-10.47s-8.01,4.14-8.62,10.47Z" />
        <path d="m1378.15,129.05c-14.08,0-22.62-9.42-22.62-23.58,0-12.94,8.45-23.76,21.38-23.76,13.73,0,20.15,11,20.15,23.58,0,1.06-.18,2.99-.26,3.96h-28.69c1.32,6.34,6.07,9.5,12.5,9.5,5.37,0,10.56-1.85,15.31-4.49l.18.18-3.43,11.18c-4.31,2.11-8.36,3.43-14.52,3.43Zm-10.03-27.37h16.72c-.44-6.42-3.34-10.47-8.1-10.47s-8.01,4.14-8.62,10.47Z" />
        <path d="m1418.01,97.46v30.62h-13.02v-44.09l12.67-1.67v8.45c4.49-6.25,8.89-8.8,14.7-8.8,8.18,0,13.9,5.9,13.9,16.54v29.57h-13.02v-27.02c0-4.75-2.29-7.66-6.6-7.66-2.82,0-5.1.97-8.62,4.05Z" />
      </g>
      <g>
        <path d="m21.3,205.44v30.62h-13.02v-44.09l12.67-1.67v8.45c4.49-6.25,8.89-8.8,14.7-8.8,8.18,0,13.9,5.9,13.9,16.54v29.57h-13.02v-27.02c0-4.75-2.29-7.66-6.6-7.66-2.82,0-5.1.97-8.62,4.05Z" />
        <path d="m84.49,230.07c-4.93,4.49-9.68,6.78-14.96,6.78-7.22,0-12.41-4.58-12.41-12.14,0-8.54,6.69-14.7,27.02-15.4-.26-5.9-2.99-8.54-8.36-8.54-4.31,0-9.24,1.76-14.34,5.72l-.26-.18,1.94-12.06c3.87-2.38,8.89-4.22,15.49-4.22,11.88,0,18.48,6.34,18.48,19.1v25.87l-12.58,1.67v-6.6Zm-10.38-2.38c2.55,0,5.72-.97,10.03-3.34v-8.71c-12.85,1.23-14.96,4.66-14.96,7.74,0,2.73,1.85,4.31,4.93,4.31Z" />
        <path d="m119.77,236.06h-13.02v-44.09l12.67-1.67v7.92c4.31-5.81,8.45-8.27,13.9-8.27s10.21,3.08,12.32,8.71c4.49-6.16,8.8-8.71,14.61-8.71,8.1,0,13.64,5.9,13.64,16.37v29.74h-13.02v-27.1c0-4.75-2.11-7.57-6.25-7.57-2.64,0-4.75.88-7.74,3.61v31.06h-13.02v-27.1c0-4.75-2.2-7.57-6.34-7.57-2.55,0-4.66.88-7.74,3.61v31.06Z" />
        <path d="m203.81,237.03c-14.08,0-22.62-9.42-22.62-23.58,0-12.94,8.45-23.76,21.38-23.76,13.73,0,20.15,11,20.15,23.58,0,1.06-.18,2.99-.26,3.96h-28.69c1.32,6.34,6.07,9.5,12.5,9.5,5.37,0,10.56-1.85,15.31-4.49l.18.18-3.43,11.18c-4.31,2.11-8.36,3.43-14.52,3.43Zm-10.03-27.37h16.72c-.44-6.42-3.34-10.47-8.1-10.47s-8.01,4.14-8.62,10.47Z" />
        <path d="m259.34,229.99c-3.96,4.58-8.01,6.95-13.46,6.95-9.94,0-17.78-9.15-17.78-22.53,0-14.87,9.15-24.2,19.98-24.2,4.14,0,7.74,1.58,11,4.22v-21.03l13.02-3.52v66.17h-12.76v-6.07Zm-8.8-3.96c2.9,0,5.46-.97,8.54-3.17v-19.54c-2.82-1.58-5.19-2.29-7.83-2.29-5.98,0-10.38,4.75-10.38,12.58s4.22,12.41,9.68,12.41Z" />
        <path d="m336.34,211.15l16.01-36.69h19.27v61.6h-13.46l.09-45.32-21.03,46.02h-2.11l-21.03-45.85.09,45.14h-13.02v-61.6h19.1l16.1,36.69Z" />
        <path d="m403.13,237.03c-14.08,0-22.62-9.42-22.62-23.58,0-12.94,8.45-23.76,21.38-23.76,13.73,0,20.15,11,20.15,23.58,0,1.06-.18,2.99-.26,3.96h-28.69c1.32,6.34,6.07,9.5,12.5,9.5,5.37,0,10.56-1.85,15.31-4.49l.18.18-3.43,11.18c-4.31,2.11-8.36,3.43-14.52,3.43Zm-10.03-27.37h16.72c-.44-6.42-3.34-10.47-8.1-10.47s-8.01,4.14-8.62,10.47Zm12.14-41.71h15.49v.18l-15.75,15.4h-6.25l6.51-15.58Z" />
        <path d="m447.92,221.27l-9.06,14.78h-13.9l15.22-23.14-14.52-22h14.43l8.36,13.55,8.27-13.55h13.82l-14.34,22,15.22,23.14h-14.43l-9.06-14.78Z" />
        <path d="m484.79,184.05c-4.49,0-7.83-3.17-7.83-7.39s3.34-7.39,7.83-7.39,7.74,3.17,7.74,7.39-3.34,7.39-7.74,7.39Zm-6.51,6.86h13.02v45.14h-13.02v-45.14Z" />
        <path d="m521.05,237.11c-12.23,0-21.56-8.98-21.56-23.67,0-13.38,9.06-23.58,22.53-23.58,5.98,0,10.21,1.85,13.64,4.31v12.94l-.18.09c-3.7-3.26-7.83-5.81-12.32-5.81-6.42,0-10.74,5.19-10.74,12.14s4.31,11.97,10.47,11.97c4.66,0,9.77-2.38,13.29-5.28l.18.18-1.5,12.67c-3.61,2.29-8.18,4.05-13.82,4.05Z" />
        <path d="m565.05,189.68c14.34,0,22.7,11.09,22.7,23.67s-8.8,23.94-22.79,23.94-22.7-11.18-22.7-23.76,8.8-23.85,22.79-23.85Zm-10.03,23.85c0,7.66,4.14,12.67,9.94,12.67s10.03-5.28,10.03-12.85-4.14-12.67-9.94-12.67-10.03,5.28-10.03,12.85Z" />
        <path d="m618.73,205.7v11.53h-24.2v-11.53h24.2Z" />
        <path d="m654.28,186.78v49.28h-13.82v-49.28h-19.62v-12.32h53.06v12.32h-19.62Z" />
        <path d="m694.67,237.03c-14.08,0-22.62-9.42-22.62-23.58,0-12.94,8.45-23.76,21.38-23.76,13.73,0,20.15,11,20.15,23.58,0,1.06-.18,2.99-.26,3.96h-28.69c1.32,6.34,6.07,9.5,12.5,9.5,5.37,0,10.56-1.85,15.31-4.49l.18.18-3.43,11.18c-4.31,2.11-8.36,3.43-14.52,3.43Zm-10.03-27.37h16.72c-.44-6.42-3.34-10.47-8.1-10.47s-8.01,4.14-8.62,10.47Z" />
        <path d="m734.53,205.44v30.62h-13.02v-44.09l12.67-1.67v8.45c4.49-6.25,8.89-8.8,14.7-8.8,8.18,0,13.9,5.9,13.9,16.54v29.57h-13.02v-27.02c0-4.75-2.29-7.66-6.6-7.66-2.82,0-5.1.97-8.62,4.05Z" />
        <path d="m792.88,189.68c14.34,0,22.7,11.09,22.7,23.67s-8.8,23.94-22.79,23.94-22.7-11.18-22.7-23.76,8.8-23.85,22.79-23.85Zm-10.03,23.85c0,7.66,4.14,12.67,9.94,12.67s10.03-5.28,10.03-12.85-4.14-12.67-9.94-12.67-10.03,5.28-10.03,12.85Z" />
        <path d="m842.42,237.11c-12.23,0-21.56-8.98-21.56-23.67,0-13.38,9.06-23.58,22.53-23.58,5.98,0,10.21,1.85,13.64,4.31v12.94l-.18.09c-3.7-3.26-7.83-5.81-12.32-5.81-6.42,0-10.74,5.19-10.74,12.14s4.31,11.97,10.47,11.97c4.66,0,9.77-2.38,13.29-5.28l.18.18-1.5,12.67c-3.61,2.29-8.18,4.05-13.82,4.05Z" />
        <path d="m879.29,205.44v30.62h-13.02v-62.65l13.02-3.52v28.33c4.4-5.81,8.62-8.27,14.34-8.27,8.18,0,13.9,5.9,13.9,16.54v29.57h-13.02v-27.02c0-4.75-2.29-7.66-6.6-7.66-2.82,0-5.19.97-8.62,4.05Z" />
        <path d="m934.73,237.03c-8.89,0-14.26-5.1-14.26-16.19v-19.71h-6.16v-10.21h6.16v-9.59l12.85-3.26v12.85h14.17v10.21h-14.17v18.57c0,4.4,2.29,6.25,6.07,6.25,2.9,0,5.37-1.23,8.1-3.26l.18.09-1.85,11.26c-2.9,1.85-6.78,2.99-11.09,2.99Z" />
        <path d="m962.54,184.05c-4.49,0-7.83-3.17-7.83-7.39s3.34-7.39,7.83-7.39,7.74,3.17,7.74,7.39-3.34,7.39-7.74,7.39Zm-6.51,6.86h13.02v45.14h-13.02v-45.14Z" />
        <path d="m997.12,237.03c-8.89,0-14.26-5.1-14.26-16.19v-19.71h-6.16v-10.21h6.16v-9.59l12.85-3.26v12.85h14.17v10.21h-14.17v18.57c0,4.4,2.29,6.25,6.07,6.25,2.9,0,5.37-1.23,8.1-3.26l.18.09-1.85,11.26c-2.9,1.85-6.78,2.99-11.09,2.99Z" />
        <path d="m1018.42,173.4l13.02-3.52v66.17h-13.02v-62.65Z" />
        <path d="m1067.25,230.07c-4.93,4.49-9.68,6.78-14.96,6.78-7.22,0-12.41-4.58-12.41-12.14,0-8.54,6.69-14.7,27.01-15.4-.26-5.9-2.99-8.54-8.36-8.54-4.31,0-9.24,1.76-14.34,5.72l-.26-.18,1.94-12.06c3.87-2.38,8.89-4.22,15.49-4.22,11.88,0,18.48,6.34,18.48,19.1v25.87l-12.58,1.67v-6.6Zm-10.38-2.38c2.55,0,5.72-.97,10.03-3.34v-8.71c-12.85,1.23-14.96,4.66-14.96,7.74,0,2.73,1.85,4.31,4.93,4.31Z" />
        <path d="m1102.54,205.44v30.62h-13.02v-44.09l12.67-1.67v8.45c4.49-6.25,8.89-8.8,14.7-8.8,8.18,0,13.9,5.9,13.9,16.54v29.57h-13.02v-27.02c0-4.75-2.29-7.66-6.6-7.66-2.82,0-5.1.97-8.62,4.05Z" />
        <path d="m1178.57,174.28h14.96l23.67,61.77h-14.87l-4.49-12.76h-24.64l-4.66,12.76h-14.25l24.29-61.77Zm15.58,37.84l-8.54-23.5-8.62,23.5h17.16Z" />
        <path d="m1244.57,236.94h-9.59l-18.83-46.02h13.99l10.03,28.16,10.47-28.16h13.64l-19.71,46.02Z" />
        <path d="m1289.01,237.03c-14.08,0-22.62-9.42-22.62-23.58,0-12.94,8.45-23.76,21.38-23.76,13.73,0,20.15,11,20.15,23.58,0,1.06-.18,2.99-.26,3.96h-28.69c1.32,6.34,6.07,9.5,12.5,9.5,5.37,0,10.56-1.85,15.31-4.49l.18.18-3.43,11.18c-4.31,2.11-8.36,3.43-14.52,3.43Zm-10.03-27.37h16.72c-.44-6.42-3.34-10.47-8.1-10.47s-8.01,4.14-8.62,10.47Z" />
        <path d="m1328.87,205.44v30.62h-13.02v-44.09l12.67-1.67v8.45c4.49-6.25,8.89-8.8,14.7-8.8,8.18,0,13.9,5.9,13.9,16.54v29.57h-13.02v-27.02c0-4.75-2.29-7.66-6.6-7.66-2.82,0-5.1.97-8.62,4.05Z" />
        <path d="m1395.05,228.23c-4.49,6.16-8.89,8.71-14.61,8.71-8.27,0-13.99-5.9-13.99-16.46v-29.57h13.02v27.02c0,4.75,2.29,7.57,6.6,7.57,2.82,0,5.19-.97,8.62-3.96v-30.62h13.02v45.14h-12.67v-7.83Z" />
        <path d="m1438.25,237.03c-14.08,0-22.62-9.42-22.62-23.58,0-12.94,8.45-23.76,21.38-23.76,13.73,0,20.15,11,20.15,23.58,0,1.06-.18,2.99-.26,3.96h-28.69c1.32,6.34,6.07,9.5,12.5,9.5,5.37,0,10.56-1.85,15.31-4.49l.18.18-3.43,11.18c-4.31,2.11-8.36,3.43-14.52,3.43Zm-10.03-27.37h16.72c-.44-6.42-3.34-10.47-8.1-10.47s-8.01,4.14-8.62,10.47Z" />
        <path d="m1469.94,220.83c4.49,0,7.92,3.7,7.92,8.01s-3.43,8.1-7.92,8.1-7.92-3.7-7.92-8.1,3.52-8.01,7.92-8.01Z" />
      </g>
      <path d="m44.27,436.04H6.61l-.7-5.72,20.33-26.05c4.31-5.37,5.9-8.27,5.9-11.79,0-3.78-2.55-6.86-6.86-6.86-5.72,0-11.18,4.4-16.81,10.91l-.18-.09-1.14-13.73c4.66-5.28,11.09-9.24,20.15-9.24,11.44,0,18.13,7.13,18.13,16.98,0,6.78-2.82,11.97-9.15,19.45l-11.18,14.61,23.14-.18-3.96,11.7Z" />
      <g>
        <path d="m69.35,405.42v30.62h-13.02v-44.09l12.67-1.67v8.45c4.49-6.25,8.89-8.8,14.7-8.8,8.18,0,13.9,5.9,13.9,16.54v29.57h-13.02v-27.02c0-4.75-2.29-7.66-6.6-7.66-2.82,0-5.1.97-8.62,4.05Z" />
        <path d="m136.23,429.97c-3.96,4.58-8.01,6.95-13.46,6.95-9.94,0-17.78-9.15-17.78-22.53,0-14.87,9.15-24.2,19.98-24.2,4.14,0,7.74,1.58,11,4.22v-21.03l13.02-3.52v66.17h-12.76v-6.07Zm-8.8-3.96c2.9,0,5.46-.97,8.54-3.17v-19.54c-2.82-1.58-5.19-2.29-7.83-2.29-5.98,0-10.38,4.75-10.38,12.58s4.22,12.41,9.68,12.41Z" />
        <path d="m197.39,374.27h14.96l23.67,61.77h-14.87l-4.49-12.76h-24.64l-4.66,12.76h-14.26l24.29-61.77Zm15.58,37.84l-8.54-23.5-8.62,23.5h17.16Z" />
        <path d="m263.39,436.92h-9.59l-18.83-46.02h13.99l10.03,28.16,10.47-28.16h13.64l-19.71,46.02Z" />
        <path d="m307.74,437.01c-14.08,0-22.62-9.42-22.62-23.58,0-12.94,8.45-23.76,21.38-23.76,13.73,0,20.15,11,20.15,23.58,0,1.06-.18,2.99-.26,3.96h-28.69c1.32,6.33,6.07,9.5,12.5,9.5,5.37,0,10.56-1.85,15.31-4.49l.18.18-3.43,11.18c-4.31,2.11-8.36,3.43-14.52,3.43Zm-10.03-27.37h16.72c-.44-6.42-3.34-10.47-8.1-10.47s-8.01,4.14-8.62,10.47Z" />
        <path d="m347.6,405.42v30.62h-13.02v-44.09l12.67-1.67v8.45c4.49-6.25,8.89-8.8,14.7-8.8,8.18,0,13.9,5.9,13.9,16.54v29.57h-13.02v-27.02c0-4.75-2.29-7.66-6.6-7.66-2.82,0-5.1.97-8.62,4.05Z" />
        <path d="m413.78,428.21c-4.49,6.16-8.89,8.71-14.61,8.71-8.27,0-13.99-5.9-13.99-16.46v-29.57h13.02v27.01c0,4.75,2.29,7.57,6.6,7.57,2.82,0,5.19-.97,8.62-3.96v-30.62h13.02v45.14h-12.67v-7.83Z" />
        <path d="m456.98,437.01c-14.08,0-22.62-9.42-22.62-23.58,0-12.94,8.45-23.76,21.38-23.76,13.73,0,20.15,11,20.15,23.58,0,1.06-.18,2.99-.26,3.96h-28.69c1.32,6.33,6.07,9.5,12.5,9.5,5.37,0,10.56-1.85,15.31-4.49l.18.18-3.43,11.18c-4.31,2.11-8.36,3.43-14.52,3.43Zm-10.03-27.37h16.72c-.44-6.42-3.34-10.47-8.1-10.47s-8.01,4.14-8.62,10.47Z" />
        <path d="m508.2,384.04c-4.49,0-7.83-3.17-7.83-7.39s3.34-7.39,7.83-7.39,7.74,3.17,7.74,7.39-3.34,7.39-7.74,7.39Zm-6.51,6.86h13.02v45.14h-13.02v-45.14Z" />
        <path d="m538.56,405.42v30.62h-13.02v-44.09l12.67-1.67v8.45c4.49-6.25,8.89-8.8,14.7-8.8,8.18,0,13.9,5.9,13.9,16.54v29.57h-13.02v-27.02c0-4.75-2.29-7.66-6.6-7.66-2.82,0-5.1.97-8.62,4.05Z" />
        <path d="m595.23,374.44h36.43l2.29,11.88h-25.08v13.11h20.86v10.91h-20.86v13.82h25.25l-2.2,11.88h-36.69v-61.6Z" />
        <path d="m667.92,430.06c-4.93,4.49-9.68,6.78-14.96,6.78-7.22,0-12.41-4.58-12.41-12.14,0-8.54,6.69-14.7,27.01-15.4-.26-5.9-2.99-8.54-8.36-8.54-4.31,0-9.24,1.76-14.34,5.72l-.26-.18,1.94-12.06c3.87-2.38,8.89-4.22,15.49-4.22,11.88,0,18.48,6.34,18.48,19.1v25.87l-12.58,1.67v-6.6Zm-10.38-2.38c2.55,0,5.72-.97,10.03-3.34v-8.71c-12.85,1.23-14.96,4.66-14.96,7.74,0,2.73,1.85,4.31,4.93,4.31Z" />
        <path d="m705.75,437.01c-6.6,0-11.88-1.76-16.63-4.75l-1.67-12.23.18-.09c5.63,4.14,12.32,7.3,18.57,7.3,3.61,0,5.81-1.41,5.81-3.52,0-2.29-1.67-3.43-8.89-5.63-8.71-2.82-14.7-6.16-14.7-14.34s6.78-13.82,17.95-13.82c6.16,0,11.09,1.76,14.78,3.87v11.26h-.18c-4.4-2.9-10.12-5.46-14.96-5.46-3.61,0-5.54,1.41-5.54,3.34,0,2.2,1.94,3.26,8.54,5.19,9.68,3.08,15.05,6.51,15.05,14.87s-7.3,13.99-18.3,13.99Z" />
        <path d="m748.7,437.01c-8.89,0-14.26-5.1-14.26-16.19v-19.71h-6.16v-10.21h6.16v-9.59l12.85-3.26v12.85h14.17v10.21h-14.17v18.57c0,4.4,2.29,6.25,6.07,6.25,2.9,0,5.37-1.23,8.1-3.26l.18.09-1.85,11.26c-2.9,1.85-6.78,2.99-11.09,2.99Z" />
        <path d="m827.19,411.05h-25.25v24.99h-13.82v-61.6h13.82v24.02h25.25v-24.02h13.82v61.6h-13.82v-24.99Z" />
        <path d="m877.53,430.06c-4.93,4.49-9.68,6.78-14.96,6.78-7.22,0-12.41-4.58-12.41-12.14,0-8.54,6.69-14.7,27.01-15.4-.26-5.9-2.99-8.54-8.36-8.54-4.31,0-9.24,1.76-14.34,5.72l-.26-.18,1.94-12.06c3.87-2.38,8.89-4.22,15.49-4.22,11.88,0,18.48,6.34,18.48,19.1v25.87l-12.58,1.67v-6.6Zm-10.38-2.38c2.55,0,5.72-.97,10.03-3.34v-8.71c-12.85,1.23-14.96,4.66-14.96,7.74,0,2.73,1.85,4.31,4.93,4.31Z" />
        <path d="m912.82,409.38v26.66h-13.02v-44.09l12.67-1.67v11.7c5.54-8.71,8.98-11.88,13.64-11.88.97,0,1.58.09,2.64.35l.7,13.82c-.88-.09-1.76-.18-2.99-.18-4.66,0-8.98,1.85-13.64,5.28Z" />
        <path d="m936.66,373.39l13.02-3.52v66.17h-13.02v-62.65Z" />
        <path d="m980.49,437.01c-14.08,0-22.62-9.42-22.62-23.58,0-12.94,8.45-23.76,21.38-23.76,13.73,0,20.15,11,20.15,23.58,0,1.06-.18,2.99-.26,3.96h-28.69c1.32,6.33,6.07,9.5,12.5,9.5,5.37,0,10.56-1.85,15.31-4.49l.18.18-3.43,11.18c-4.31,2.11-8.36,3.43-14.52,3.43Zm-10.03-27.37h16.72c-.44-6.42-3.34-10.47-8.1-10.47s-8.01,4.14-8.62,10.47Z" />
        <path d="m1020.35,436.04h-13.02v-44.09l12.67-1.67v7.92c4.31-5.81,8.45-8.27,13.9-8.27s10.21,3.08,12.32,8.71c4.49-6.16,8.8-8.71,14.61-8.71,8.1,0,13.64,5.9,13.64,16.37v29.74h-13.02v-27.1c0-4.75-2.11-7.57-6.25-7.57-2.64,0-4.75.88-7.74,3.61v31.06h-13.02v-27.1c0-4.75-2.2-7.57-6.34-7.57-2.55,0-4.66.88-7.74,3.61v31.06Z" />
        <path d="m1114.95,405.42v30.62h-13.02v-62.65l13.02-3.52v28.33c4.4-5.81,8.62-8.27,14.34-8.27,8.18,0,13.9,5.9,13.9,16.54v29.57h-13.02v-27.02c0-4.75-2.29-7.66-6.6-7.66-2.82,0-5.19.97-8.62,4.05Z" />
        <path d="m1178.13,430.06c-4.93,4.49-9.68,6.78-14.96,6.78-7.22,0-12.41-4.58-12.41-12.14,0-8.54,6.69-14.7,27.01-15.4-.26-5.9-2.99-8.54-8.36-8.54-4.31,0-9.24,1.76-14.34,5.72l-.26-.18,1.94-12.06c3.87-2.38,8.89-4.22,15.49-4.22,11.88,0,18.48,6.34,18.48,19.1v25.87l-12.58,1.67v-6.6Zm-10.38-2.38c2.55,0,5.72-.97,10.03-3.34v-8.71c-12.85,1.23-14.96,4.66-14.96,7.74,0,2.73,1.85,4.31,4.93,4.31Z" />
        <path d="m1215.97,437.01c-6.6,0-11.88-1.76-16.63-4.75l-1.67-12.23.18-.09c5.63,4.14,12.32,7.3,18.57,7.3,3.61,0,5.81-1.41,5.81-3.52,0-2.29-1.67-3.43-8.89-5.63-8.71-2.82-14.7-6.16-14.7-14.34s6.78-13.82,17.95-13.82c6.16,0,11.09,1.76,14.78,3.87v11.26h-.18c-4.4-2.9-10.12-5.46-14.96-5.46-3.61,0-5.54,1.41-5.54,3.34,0,2.2,1.94,3.26,8.54,5.19,9.68,3.08,15.05,6.51,15.05,14.87s-7.3,13.99-18.3,13.99Z" />
        <path d="m1280.12,436.75c-3.96,0-7.57-.88-11.26-2.29l-8.18,1.76h-.97v-62.83l13.02-3.52v26.4c3.87-4.14,7.83-6.25,12.94-6.25,10.12,0,17.69,8.98,17.69,22.35,0,15.13-10.47,24.38-23.23,24.38Zm-7.39-33.09v21.21c2.11.79,4.14,1.14,6.42,1.14,6.6,0,11.44-4.84,11.44-12.94s-4.05-12.14-9.68-12.14c-2.82,0-5.28.79-8.18,2.73Z" />
        <path d="m1331.33,437.01c-14.08,0-22.62-9.42-22.62-23.58,0-12.94,8.45-23.76,21.38-23.76,13.73,0,20.15,11,20.15,23.58,0,1.06-.18,2.99-.26,3.96h-28.69c1.32,6.33,6.07,9.5,12.5,9.5,5.37,0,10.56-1.85,15.31-4.49l.18.18-3.43,11.18c-4.31,2.11-8.36,3.43-14.52,3.43Zm-10.03-27.37h16.72c-.44-6.42-3.34-10.47-8.1-10.47s-8.01,4.14-8.62,10.47Z" />
        <path d="m1378.15,437.01c-14.08,0-22.62-9.42-22.62-23.58,0-12.94,8.45-23.76,21.38-23.76,13.73,0,20.15,11,20.15,23.58,0,1.06-.18,2.99-.26,3.96h-28.69c1.32,6.33,6.07,9.5,12.5,9.5,5.37,0,10.56-1.85,15.31-4.49l.18.18-3.43,11.18c-4.31,2.11-8.36,3.43-14.52,3.43Zm-10.03-27.37h16.72c-.44-6.42-3.34-10.47-8.1-10.47s-8.01,4.14-8.62,10.47Z" />
        <path d="m1418.01,405.42v30.62h-13.02v-44.09l12.67-1.67v8.45c4.49-6.25,8.89-8.8,14.7-8.8,8.18,0,13.9,5.9,13.9,16.54v29.57h-13.02v-27.02c0-4.75-2.29-7.66-6.6-7.66-2.82,0-5.1.97-8.62,4.05Z" />
      </g>
      <g>
        <path d="m21.3,513.39v30.62h-13.02v-44.09l12.67-1.67v8.45c4.49-6.25,8.89-8.8,14.7-8.8,8.18,0,13.9,5.9,13.9,16.54v29.57h-13.02v-27.02c0-4.75-2.29-7.66-6.6-7.66-2.82,0-5.1.97-8.62,4.05Z" />
        <path d="m84.49,538.03c-4.93,4.49-9.68,6.78-14.96,6.78-7.22,0-12.41-4.58-12.41-12.14,0-8.54,6.69-14.7,27.02-15.4-.26-5.9-2.99-8.54-8.36-8.54-4.31,0-9.24,1.76-14.34,5.72l-.26-.18,1.94-12.06c3.87-2.38,8.89-4.22,15.49-4.22,11.88,0,18.48,6.34,18.48,19.1v25.87l-12.58,1.67v-6.6Zm-10.38-2.38c2.55,0,5.72-.97,10.03-3.34v-8.71c-12.85,1.23-14.96,4.66-14.96,7.74,0,2.73,1.85,4.31,4.93,4.31Z" />
        <path d="m119.77,544.02h-13.02v-44.09l12.67-1.67v7.92c4.31-5.81,8.45-8.27,13.9-8.27s10.21,3.08,12.32,8.71c4.49-6.16,8.8-8.71,14.61-8.71,8.1,0,13.64,5.9,13.64,16.37v29.74h-13.02v-27.1c0-4.75-2.11-7.57-6.25-7.57-2.64,0-4.75.88-7.74,3.61v31.06h-13.02v-27.1c0-4.75-2.2-7.57-6.34-7.57-2.55,0-4.66.88-7.74,3.61v31.06Z" />
        <path d="m203.81,544.99c-14.08,0-22.62-9.42-22.62-23.58,0-12.94,8.45-23.76,21.38-23.76,13.73,0,20.15,11,20.15,23.58,0,1.06-.18,2.99-.26,3.96h-28.69c1.32,6.33,6.07,9.5,12.5,9.5,5.37,0,10.56-1.85,15.31-4.49l.18.18-3.43,11.18c-4.31,2.11-8.36,3.43-14.52,3.43Zm-10.03-27.37h16.72c-.44-6.42-3.34-10.47-8.1-10.47s-8.01,4.14-8.62,10.47Z" />
        <path d="m259.34,537.95c-3.96,4.58-8.01,6.95-13.46,6.95-9.94,0-17.78-9.15-17.78-22.53,0-14.87,9.15-24.2,19.98-24.2,4.14,0,7.74,1.58,11,4.22v-21.03l13.02-3.52v66.17h-12.76v-6.07Zm-8.8-3.96c2.9,0,5.46-.97,8.54-3.17v-19.54c-2.82-1.58-5.19-2.29-7.83-2.29-5.98,0-10.38,4.75-10.38,12.58s4.22,12.41,9.68,12.41Z" />
        <path d="m336.34,519.11l16.01-36.7h19.27v61.6h-13.46l.09-45.32-21.03,46.02h-2.11l-21.03-45.85.09,45.14h-13.02v-61.6h19.1l16.1,36.7Z" />
        <path d="m385.62,484.62h28.51v-11h2.29v13.2h-30.8v-2.2Zm30.8,27.46l-30.8-7.66v-4.4l30.8-7.74v2.38l-8.8,2.2v10.74l8.8,2.2v2.29Zm-30.8,5.54h30.8v2.2h-30.8v-2.2Zm30.8,23.06h-30.8v-5.46c0-6.6,4.4-9.86,9.94-9.86,4.84,0,8.8,2.55,9.68,7.66l11-8.27h.18v2.73l-11,8.01v2.99h11v2.2Zm-30.8,4.49h2.29v7.13h28.51v2.2h-28.51v7.13h-2.29v-16.46Zm19.71-38.19v-9.5l-17.42,4.31v.88l17.42,4.31Zm-2.2,31.5v-3.26c0-5.1-3.43-7.74-7.57-7.74s-7.66,2.64-7.66,7.74v3.26h15.22Z" />
        <path d="m447.92,529.23l-9.06,14.78h-13.9l15.22-23.14-14.52-22h14.43l8.36,13.55,8.27-13.55h13.82l-14.34,22,15.22,23.14h-14.43l-9.06-14.78Z" />
        <path d="m484.79,492.01c-4.49,0-7.83-3.17-7.83-7.39s3.34-7.39,7.83-7.39,7.74,3.17,7.74,7.39-3.34,7.39-7.74,7.39Zm-6.51,6.86h13.02v45.14h-13.02v-45.14Z" />
        <path d="m521.05,545.07c-12.23,0-21.56-8.98-21.56-23.67,0-13.38,9.06-23.58,22.53-23.58,5.98,0,10.21,1.85,13.64,4.31v12.94l-.18.09c-3.7-3.26-7.83-5.81-12.32-5.81-6.42,0-10.74,5.19-10.74,12.14s4.31,11.97,10.47,11.97c4.66,0,9.77-2.38,13.29-5.28l.18.18-1.5,12.67c-3.61,2.29-8.18,4.05-13.82,4.05Z" />
        <path d="m565.05,497.64c14.34,0,22.7,11.09,22.7,23.67s-8.8,23.94-22.79,23.94-22.7-11.18-22.7-23.76,8.8-23.85,22.79-23.85Zm-10.03,23.85c0,7.66,4.14,12.67,9.94,12.67s10.03-5.28,10.03-12.85-4.14-12.67-9.94-12.67-10.03,5.28-10.03,12.85Z" />
        <path d="m598.14,484.62h14.34v-11h2.29v13.2h-16.63v-2.2Zm16.63,27.46l-16.63-7.66v-4.4l16.63-7.74v2.46l-4.75,2.2v10.56l4.75,2.2v2.38Zm-16.63,5.54h16.63v2.2h-16.63v-2.2Zm16.63,23.06h-16.63v-9.94c0-3.61,2.38-5.37,5.37-5.37s5.37,1.76,5.37,5.37v1.23l5.72-7.66h.18v3.17l-5.9,7.39v3.61h5.9v2.2Zm-16.63,4.49h2.29v7.13h14.34v2.2h-14.34v7.13h-2.29v-16.46Zm9.59-38.72v-8.45l-7.3,3.43v1.58l7.3,3.43Zm-1.14,32.03v-7.74c0-2.11-1.41-3.26-3.08-3.26s-3.08,1.14-3.08,3.26v7.74h6.16Z" />
        <path d="m654.28,494.74v49.28h-13.82v-49.28h-19.62v-12.32h53.06v12.32h-19.62Z" />
        <path d="m694.67,544.99c-14.08,0-22.62-9.42-22.62-23.58,0-12.94,8.45-23.76,21.38-23.76,13.73,0,20.15,11,20.15,23.58,0,1.06-.18,2.99-.26,3.96h-28.69c1.32,6.33,6.07,9.5,12.5,9.5,5.37,0,10.56-1.85,15.31-4.49l.18.18-3.43,11.18c-4.31,2.11-8.36,3.43-14.52,3.43Zm-10.03-27.37h16.72c-.44-6.42-3.34-10.47-8.1-10.47s-8.01,4.14-8.62,10.47Z" />
        <path d="m734.53,513.39v30.62h-13.02v-44.09l12.67-1.67v8.45c4.49-6.25,8.89-8.8,14.7-8.8,8.18,0,13.9,5.9,13.9,16.54v29.57h-13.02v-27.02c0-4.75-2.29-7.66-6.6-7.66-2.82,0-5.1.97-8.62,4.05Z" />
        <path d="m792.88,497.64c14.34,0,22.7,11.09,22.7,23.67s-8.8,23.94-22.79,23.94-22.7-11.18-22.7-23.76,8.8-23.85,22.79-23.85Zm-10.03,23.85c0,7.66,4.14,12.67,9.94,12.67s10.03-5.28,10.03-12.85-4.14-12.67-9.94-12.67-10.03,5.28-10.03,12.85Z" />
        <path d="m842.42,545.07c-12.23,0-21.56-8.98-21.56-23.67,0-13.38,9.06-23.58,22.53-23.58,5.98,0,10.21,1.85,13.64,4.31v12.94l-.18.09c-3.7-3.26-7.83-5.81-12.32-5.81-6.42,0-10.74,5.19-10.74,12.14s4.31,11.97,10.47,11.97c4.66,0,9.77-2.38,13.29-5.28l.18.18-1.5,12.67c-3.61,2.29-8.18,4.05-13.82,4.05Z" />
        <path d="m879.29,513.39v30.62h-13.02v-62.65l13.02-3.52v28.33c4.4-5.81,8.62-8.27,14.34-8.27,8.18,0,13.9,5.9,13.9,16.54v29.57h-13.02v-27.02c0-4.75-2.29-7.66-6.6-7.66-2.82,0-5.19.97-8.62,4.05Z" />
        <path d="m934.73,544.99c-8.89,0-14.26-5.1-14.26-16.19v-19.71h-6.16v-10.21h6.16v-9.59l12.85-3.26v12.85h14.17v10.21h-14.17v18.57c0,4.4,2.29,6.25,6.07,6.25,2.9,0,5.37-1.23,8.1-3.26l.18.09-1.85,11.26c-2.9,1.85-6.78,2.99-11.09,2.99Z" />
        <path d="m962.54,492.01c-4.49,0-7.83-3.17-7.83-7.39s3.34-7.39,7.83-7.39,7.74,3.17,7.74,7.39-3.34,7.39-7.74,7.39Zm-6.51,6.86h13.02v45.14h-13.02v-45.14Z" />
        <path d="m997.12,544.99c-8.89,0-14.26-5.1-14.26-16.19v-19.71h-6.16v-10.21h6.16v-9.59l12.85-3.26v12.85h14.17v10.21h-14.17v18.57c0,4.4,2.29,6.25,6.07,6.25,2.9,0,5.37-1.23,8.1-3.26l.18.09-1.85,11.26c-2.9,1.85-6.78,2.99-11.09,2.99Z" />
        <path d="m1018.42,481.36l13.02-3.52v66.17h-13.02v-62.65Z" />
        <path d="m1067.25,538.03c-4.93,4.49-9.68,6.78-14.96,6.78-7.22,0-12.41-4.58-12.41-12.14,0-8.54,6.69-14.7,27.01-15.4-.26-5.9-2.99-8.54-8.36-8.54-4.31,0-9.24,1.76-14.34,5.72l-.26-.18,1.94-12.06c3.87-2.38,8.89-4.22,15.49-4.22,11.88,0,18.48,6.34,18.48,19.1v25.87l-12.58,1.67v-6.6Zm-10.38-2.38c2.55,0,5.72-.97,10.03-3.34v-8.71c-12.85,1.23-14.96,4.66-14.96,7.74,0,2.73,1.85,4.31,4.93,4.31Z" />
        <path d="m1102.54,513.39v30.62h-13.02v-44.09l12.67-1.67v8.45c4.49-6.25,8.89-8.8,14.7-8.8,8.18,0,13.9,5.9,13.9,16.54v29.57h-13.02v-27.02c0-4.75-2.29-7.66-6.6-7.66-2.82,0-5.1.97-8.62,4.05Z" />
        <path d="m1178.57,482.24h14.96l23.67,61.77h-14.87l-4.49-12.76h-24.64l-4.66,12.76h-14.25l24.29-61.77Zm15.58,37.84l-8.54-23.5-8.62,23.5h17.16Z" />
        <path d="m1244.57,544.9h-9.59l-18.83-46.02h13.99l10.03,28.16,10.47-28.16h13.64l-19.71,46.02Z" />
        <path d="m1289.01,544.99c-14.08,0-22.62-9.42-22.62-23.58,0-12.94,8.45-23.76,21.38-23.76,13.73,0,20.15,11,20.15,23.58,0,1.06-.18,2.99-.26,3.96h-28.69c1.32,6.33,6.07,9.5,12.5,9.5,5.37,0,10.56-1.85,15.31-4.49l.18.18-3.43,11.18c-4.31,2.11-8.36,3.43-14.52,3.43Zm-10.03-27.37h16.72c-.44-6.42-3.34-10.47-8.1-10.47s-8.01,4.14-8.62,10.47Z" />
        <path d="m1328.87,513.39v30.62h-13.02v-44.09l12.67-1.67v8.45c4.49-6.25,8.89-8.8,14.7-8.8,8.18,0,13.9,5.9,13.9,16.54v29.57h-13.02v-27.02c0-4.75-2.29-7.66-6.6-7.66-2.82,0-5.1.97-8.62,4.05Z" />
        <path d="m1395.05,536.19c-4.49,6.16-8.89,8.71-14.61,8.71-8.27,0-13.99-5.9-13.99-16.46v-29.57h13.02v27.01c0,4.75,2.29,7.57,6.6,7.57,2.82,0,5.19-.97,8.62-3.96v-30.62h13.02v45.14h-12.67v-7.83Z" />
        <path d="m1438.25,544.99c-14.08,0-22.62-9.42-22.62-23.58,0-12.94,8.45-23.76,21.38-23.76,13.73,0,20.15,11,20.15,23.58,0,1.06-.18,2.99-.26,3.96h-28.69c1.32,6.33,6.07,9.5,12.5,9.5,5.37,0,10.56-1.85,15.31-4.49l.18.18-3.43,11.18c-4.31,2.11-8.36,3.43-14.52,3.43Zm-10.03-27.37h16.72c-.44-6.42-3.34-10.47-8.1-10.47s-8.01,4.14-8.62,10.47Z" />
        <path d="m1469.94,528.79c4.49,0,7.92,3.7,7.92,8.01s-3.43,8.1-7.92,8.1-7.92-3.7-7.92-8.1,3.52-8.01,7.92-8.01Z" />
      </g>
      <g>
        <path d="m14.24,325.08l5.78-11.82h3.18v14.56h-1.94v-12.96l-6.58,13.18h-.92l-6.6-13.18v12.96h-1.88v-14.56h3.18l5.78,11.82Z" />
        <path d="m35.06,313.16h2.22l6.7,14.66h-2.16l-1.66-3.72h-8.22l-1.7,3.72h-2.06l6.88-14.66Zm4.36,9.22l-3.32-7.36-3.38,7.36h6.7Z" />
        <path d="m48.98,313.26h1.98v12.76h7.12v1.8h-9.1v-14.56Z" />
        <path d="m63.18,313.26h1.98v12.76h7.12v1.8h-9.1v-14.56Z" />
        <path d="m83.66,328.12c-4.36,0-7.66-3.34-7.66-7.56s3.36-7.6,7.7-7.6,7.64,3.34,7.64,7.56-3.36,7.6-7.68,7.6Zm-.04-13.32c-3.06,0-5.6,2.48-5.6,5.72s2.5,5.76,5.64,5.76,5.66-2.48,5.66-5.72-2.56-5.76-5.7-5.76Z" />
        <path d="m101.02,321.98h-2.18v5.84h-1.98v-14.56h5.18c2.8,0,4.94,1.48,4.94,4.3,0,2.14-1.56,3.72-3.7,4.2l5.8,6.06h-2.56l-5.5-5.84Zm-2.2-6.98v5.28h3.06c1.76,0,3.1-1.08,3.1-2.68s-1.28-2.6-3.02-2.6h-3.14Z" />
        <path d="m118.62,321.3v6.52h-1.98v-6.46l-5.76-8.1h2.36l4.42,6.34,4.4-6.34h2.28l-5.72,8.04Z" />
      </g>
      <g>
        <path d="m142.9,316.14v11.68h-3.26v-11.68h-5.3v-2.88h13.86v2.88h-5.3Z" />
        <path d="m157.58,322.64h-1.46v5.18h-3.26v-14.56h5.76c3.36,0,5.58,1.68,5.58,4.58,0,2.06-1.28,3.62-3.22,4.34l5.12,5.64h-3.94l-4.58-5.18Zm-1.52-6.74v4.18h2.4c1.5,0,2.5-.84,2.5-2.14s-1-2.04-2.44-2.04h-2.46Z" />
        <path d="m169.8,313.26h3.24v14.56h-3.24v-14.56Z" />
        <path d="m184.06,313.2h3.7l6.44,14.62h-3.48l-1.28-3.04h-7.3l-1.32,3.04h-3.36l6.6-14.62Zm4.34,8.96l-2.56-5.96-2.62,5.96h5.18Z" />
        <path d="m198.58,313.26h3.26v11.64h6.66v2.92h-9.92v-14.56Z" />
      </g>
      <g>
        <path d="m219.58,320.54c0-4.32,3.46-7.52,7.6-7.52,2.2,0,3.78.72,4.96,1.56v2.28h-.04c-1.38-1.12-2.92-1.98-4.98-1.98-2.96,0-5.52,2.46-5.52,5.62,0,3.48,2.66,5.72,5.46,5.72,2,0,3.76-.8,5.3-2.04l.04.04-.3,2.28c-1.34.88-2.94,1.56-5.22,1.56-3.94,0-7.3-2.94-7.3-7.52Z" />
        <path d="m244.56,328.12c-4.36,0-7.66-3.34-7.66-7.56s3.36-7.6,7.7-7.6,7.64,3.34,7.64,7.56-3.36,7.6-7.68,7.6Zm-.04-13.32c-3.06,0-5.6,2.48-5.6,5.72s2.5,5.76,5.64,5.76,5.66-2.48,5.66-5.72-2.56-5.76-5.7-5.76Z" />
        <path d="m266.72,325.08l5.78-11.82h3.18v14.56h-1.94v-12.96l-6.58,13.18h-.92l-6.6-13.18v12.96h-1.88v-14.56h3.18l5.78,11.82Z" />
        <path d="m284.22,322.52v5.3h-1.98v-14.56h4.88c3.02,0,5.24,1.54,5.24,4.52s-2.3,4.74-5.38,4.74h-2.76Zm0-7.5v5.74h2.76c2,0,3.38-1.1,3.38-2.96,0-1.7-1.3-2.78-3.3-2.78h-2.84Z" />
        <path d="m301.72,313.16h2.22l6.7,14.66h-2.16l-1.66-3.72h-8.22l-1.7,3.72h-2.06l6.88-14.66Zm4.36,9.22l-3.32-7.36-3.38,7.36h6.7Z" />
        <path d="m313.98,320.54c0-4.32,3.46-7.52,7.6-7.52,2.2,0,3.78.72,4.96,1.56v2.28h-.04c-1.38-1.12-2.92-1.98-4.98-1.98-2.96,0-5.52,2.46-5.52,5.62,0,3.48,2.66,5.72,5.46,5.72,2,0,3.76-.8,5.3-2.04l.04.04-.3,2.28c-1.34.88-2.94,1.56-5.22,1.56-3.94,0-7.3-2.94-7.3-7.52Z" />
        <path d="m338.6,315.04v12.78h-1.98v-12.78h-5.76v-1.78h13.52v1.78h-5.78Z" />
        <path d="m361.66,327.82h-5.48v-14.56h5.46c2.84,0,4.62,1.42,4.62,3.8,0,1.46-.88,2.56-2.3,3.18,1.8.54,2.96,1.72,2.96,3.5,0,2.52-2.2,4.08-5.26,4.08Zm-3.56-8.24h3.06c1.9,0,3.14-1,3.14-2.38s-1.1-2.24-2.82-2.24h-3.38v4.62Zm3.6,6.54c2.04,0,3.24-1.04,3.24-2.46,0-1.56-1.36-2.44-3.48-2.44h-3.36v4.9h3.6Z" />
        <path d="m379.02,328.12c-4.36,0-7.66-3.34-7.66-7.56s3.36-7.6,7.7-7.6,7.64,3.34,7.64,7.56-3.36,7.6-7.68,7.6Zm-.04-13.32c-3.06,0-5.6,2.48-5.6,5.72s2.5,5.76,5.64,5.76,5.66-2.48,5.66-5.72-2.56-5.76-5.7-5.76Z" />
        <path d="m392.22,313.26h1.98v12.76h7.12v1.8h-9.1v-14.56Z" />
        <path d="m406.42,313.26h4.84c5.06,0,8.32,2.76,8.32,7.24s-3.54,7.32-8.32,7.32h-4.84v-14.56Zm1.98,1.78v11h2.9c3.52,0,6.24-2.04,6.24-5.5s-2.62-5.5-6.32-5.5h-2.82Z" />
      </g>
    </svg>
  );
};
