import update from 'immutability-helper';
import { difference, union } from 'lodash-es';

import { State } from './index';

export type ToggleWidthSelected = {
  type: 'FONT_LICENSE/TOGGLE_WIDTH_SELECTED';
  payload: { size: string; width: string };
};

export const actionType = 'FONT_LICENSE/TOGGLE_WIDTH_SELECTED';

export function action(size: string, width: string): ToggleWidthSelected {
  return {
    type: actionType,
    payload: { size, width },
  };
}

export function reducer(state: State, action: ToggleWidthSelected) {
  const { license, family } = state;
  const { size, width } = action.payload;

  if (family == null) {
    return state;
  }

  const widthFaceIds = family.faces
    .filter((face) => face.size === size && face.width === width)
    .map((face) => face.id);

  const widthSelected = widthFaceIds.every((id) =>
    license.faceIds.includes(id),
  );

  const { faceIds } = state.license;

  return update(state, {
    license: {
      faceIds: {
        $set: widthSelected
          ? difference(faceIds, widthFaceIds)
          : union(faceIds, widthFaceIds),
      },
    },
  });
}
