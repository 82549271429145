import './Header.scss';

import React, { useState } from 'react';
import { Query } from 'react-apollo';
import { Link } from '@reach/router';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  CurrentUserQuery,
  CURRENT_USER_QUERY,
} from 'components/queries/CurrentUserQuery';
import * as Vectors from 'components/vectors';
import { State as ModulesState } from 'modules';

const Header: React.FC = () => {
  const inverted = useSelector<ModulesState>((state) => state.colors.inverted);
  const logoBackgroundColor = useSelector<ModulesState>(
    (state) => state.colors.logoBackgroundColor,
  );

  const [mobileMenuExpanded, setMobileMenuExpanded] = useState(false);

  return (
    <Query<CurrentUserQuery> query={CURRENT_USER_QUERY}>
      {({ loading, data }) => {
        if (loading || data == null) {
          return null;
        }

        const user = data.currentUser;

        return (
          <header
            className={classNames('Header', {
              'is-inverted': inverted,
            })}
          >
            <Link to="/" className="Header-logo">
              <Vectors.Logo
                textColor={
                  logoBackgroundColor != null
                    ? `#${logoBackgroundColor}`
                    : undefined
                }
              />
            </Link>

            <nav className="Header-mobileNav">
              <Link className="Header-nav-group-item" to="/cart">
                Cart
              </Link>
              <button onClick={() => setMobileMenuExpanded(true)}>Menu</button>
            </nav>

            {mobileMenuExpanded ? (
              <nav className="Header-mobileNavOverlay">
                <Link
                  className="Header-nav-group-item"
                  to="/families"
                  onClick={() => setMobileMenuExpanded(false)}
                >
                  Retail Fonts
                </Link>

                <Link
                  className="Header-nav-group-item"
                  to="/custom"
                  onClick={() => setMobileMenuExpanded(false)}
                >
                  Custom Work
                </Link>

                {window.ENV.productsAvailable && (
                  <Link
                    className="Header-nav-group-item"
                    to="/goods"
                    onClick={() => setMobileMenuExpanded(false)}
                  >
                    Goods
                  </Link>
                )}

                <Link
                  className="Header-nav-group-item"
                  to="/blog"
                  onClick={() => setMobileMenuExpanded(false)}
                >
                  Blog
                </Link>

                <Link
                  className="Header-nav-group-item"
                  to="/about"
                  onClick={() => setMobileMenuExpanded(false)}
                >
                  About
                </Link>

                <Link
                  className="Header-nav-group-item"
                  to="/in-use"
                  onClick={() => setMobileMenuExpanded(false)}
                >
                  In Use
                </Link>

                {window.ENV.trialFontsAvailable && (
                  <Link
                    className="Header-nav-group-item"
                    to="/trial-fonts"
                    onClick={() => setMobileMenuExpanded(false)}
                  >
                    Trial Fonts
                  </Link>
                )}

                <div>—</div>

                {user == null && (
                  <Link
                    className="Header-nav-group-item"
                    to="/session"
                    onClick={() => setMobileMenuExpanded(false)}
                  >
                    Sign In
                  </Link>
                )}

                {user != null && (
                  <Link
                    className="Header-nav-group-item"
                    to="/account"
                    onClick={() => setMobileMenuExpanded(false)}
                  >
                    Account
                  </Link>
                )}

                <Link
                  className="Header-nav-group-item"
                  to="/cart"
                  onClick={() => setMobileMenuExpanded(false)}
                >
                  Cart
                </Link>

                <button
                  onClick={() => setMobileMenuExpanded(false)}
                  className="Header-mobileNavCloseButton"
                >
                  Close
                </button>
              </nav>
            ) : (
              <nav className="Header-nav">
                <div className="Header-nav-group">
                  <Link className="Header-nav-group-item" to="/families">
                    Retail Fonts
                  </Link>

                  <Link className="Header-nav-group-item" to="/custom">
                    Custom Work
                  </Link>

                  {window.ENV.productsAvailable && (
                    <Link className="Header-nav-group-item" to="/goods">
                      Goods
                    </Link>
                  )}

                  <Link className="Header-nav-group-item" to="/blog">
                    Blog
                  </Link>

                  <Link className="Header-nav-group-item" to="/about">
                    About
                  </Link>

                  <Link className="Header-nav-group-item" to="/in-use">
                    In Use
                  </Link>

                  {window.ENV.trialFontsAvailable && (
                    <Link className="Header-nav-group-item" to="/trial-fonts">
                      Trial Fonts
                    </Link>
                  )}
                </div>
                <div className="Header-nav-group">
                  {user == null && (
                    <Link className="Header-nav-group-item" to="/session">
                      Sign In
                    </Link>
                  )}

                  {user != null && (
                    <Link className="Header-nav-group-item" to="/account">
                      Account
                    </Link>
                  )}

                  <Link className="Header-nav-group-item" to="/cart">
                    Cart
                  </Link>
                </div>
              </nav>
            )}
          </header>
        );
      }}
    </Query>
  );
};

export default Header;
