import React from 'react';

interface Props {
  widths: {
    w200: string | null;
    w400: string | null;
    w800: string | null;
    w1600: string | null;
    w3200: string | null;
  };
  sizes: string[];
}

const UsageExamplesImage: React.FC<Props> = (props) => {
  const { widths, sizes } = props;

  const srcSet = [
    `${widths.w200} 200w`,
    `${widths.w400} 400w`,
    `${widths.w800} 800w`,
    `${widths.w1600} 1600w`,
    `${widths.w3200} 3200w`,
  ];

  return (
    <img
      src={widths.w3200 || undefined}
      srcSet={srcSet.join(', ')}
      sizes={sizes.join(', ')}
    />
  );
};

export default UsageExamplesImage;
