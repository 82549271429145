import { State } from './types';

const initialState: State = {
  family: null,
  license: {
    faceIds: [],
    licenseOptionIds: {
      desktop: null,
      web: null,
      mobile: null,
    },
    familyHighlighted: false,
    highlightedPackage: null,
  },
  licenseOptions: [],
};

export default initialState;
