import './Footer.scss';

import React from 'react';
import { Link } from '@reach/router';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import * as Core from './core';
import { State as ModulesState } from 'modules';

export function Footer() {
  const inverted = useSelector<ModulesState>((state) => state.colors.inverted);

  return (
    <Core.ContentWrapper>
      <footer
        className={classNames('Footer', {
          'is-inverted': inverted,
        })}
      >
        <nav className="Footer-nav">
          <div className="Footer-nav-group">
            <div className="Footer-nav-group-heading">Work</div>

            <div>
              <Link className="Footer-nav-group-link" to="/families">
                Retail Fonts
              </Link>
            </div>

            <div>
              <Link className="Footer-nav-group-link" to="/custom">
                Custom Work
              </Link>
            </div>

            {window.ENV.productsAvailable && (
              <Link className="Footer-nav-group-link" to="/goods">
                Goods
              </Link>
            )}

            <div>
              <Link className="Footer-nav-group-link" to="/trial-fonts">
                Trial Fonts
              </Link>
            </div>
          </div>

          <div className="Footer-nav-group">
            <div className="Footer-nav-group-heading">Foundry</div>

            <div>
              <Link className="Footer-nav-group-link" to="/about">
                About
              </Link>
            </div>

            <div>
              <Link className="Footer-nav-group-link" to="/events">
                Events
              </Link>
            </div>

            <div>
              <Link className="Footer-nav-group-link" to="/press">
                Press
              </Link>
            </div>

            <div>
              <Link className="Footer-nav-group-link" to="/blog">
                Blog
              </Link>
            </div>
          </div>

          <div className="Footer-nav-group">
            <div className="Footer-nav-group-heading">Connect</div>

            <div>
              <Link className="Footer-nav-group-link" to="/about#contact-info">
                Contact Us
              </Link>
            </div>

            <div>
              <Link className="Footer-nav-group-link" to="/newsletter">
                Newsletter
              </Link>
            </div>

            <div>
              <Link className="Footer-nav-group-link" to="/work-with-us">
                Work with Us
              </Link>
            </div>
          </div>

          <div className="Footer-nav-group">
            <div className="Footer-nav-group-heading">Information</div>

            <div>
              <Link className="Footer-nav-group-link" to="/licensing">
                Licensing
              </Link>
            </div>

            <div>
              <Link className="Footer-nav-group-link" to="/faq">
                FAQ
              </Link>
            </div>

            <div>
              <Link className="Footer-nav-group-link" to="/privacy">
                Privacy Policy
              </Link>
            </div>

            <div>
              <Link className="Footer-nav-group-link" to="/terms">
                Terms of Use
              </Link>
            </div>
          </div>
        </nav>

        <div className="footer-sign-off">
          <div className="copyright">
            © 2015–
            {new Date().getFullYear()} Frere-Jones Type LLC
          </div>
          <div>
            Website designed by{' '}
            <a
              href="http://friendsoftheweb.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Friends of The Web
            </a>{' '}
            and{' '}
            <a
              href="https://www.championsdesign.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Champions Design
            </a>
            . Built and maintained by{' '}
            <a
              href="http://friendsoftheweb.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Friends of The Web.
            </a>
          </div>
        </div>
      </footer>
    </Core.ContentWrapper>
  );
}

export default Footer;
