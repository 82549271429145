import { takeEvery } from 'redux-saga/effects';

import * as loadData from './loadData';
import { LoadData, LoadDataSuccess } from './loadData';

import * as clearData from './clearData';
import { ClearData } from './clearData';

import * as selectLicenseOption from './selectLicenseOption';
import { SelectLicenseOption } from './selectLicenseOption';

import * as toggleFamilySelected from './toggleFamilySelected';
import { ToggleFamilySelected } from './toggleFamilySelected';

import * as toggleSizeSelected from './toggleSizeSelected';
import { ToggleSizeSelected } from './toggleSizeSelected';

import * as toggleWidthSelected from './toggleWidthSelected';
import { ToggleWidthSelected } from './toggleWidthSelected';

import * as toggleFaceSelected from './toggleFaceSelected';
import { ToggleFaceSelected } from './toggleFaceSelected';

import * as highlightPackage from './highlightPackage';
import { HighlightPackage } from './highlightPackage';

import * as highlightFamily from './highlightFamily';
import { HighlightFamily } from './highlightFamily';

import initialState from './initialState';
import { State, FontLicense } from './types';

export type { State, FontLicense };

export type Action =
  | LoadData
  | LoadDataSuccess
  | ClearData
  | SelectLicenseOption
  | ToggleFamilySelected
  | ToggleSizeSelected
  | ToggleWidthSelected
  | ToggleFaceSelected
  | HighlightPackage
  | HighlightFamily;

export const actions = {
  loadData: loadData.action,
  clearData: clearData.action,
  selectLicenseOption: selectLicenseOption.action,
  toggleFamilySelected: toggleFamilySelected.action,
  toggleSizeSelected: toggleSizeSelected.action,
  toggleWidthSelected: toggleWidthSelected.action,
  toggleFaceSelected: toggleFaceSelected.action,
  highlightPackage: highlightPackage.action,
  highlightFamily: highlightFamily.action,
};

export function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case 'FONT_LICENSE/LOAD_DATA':
    case 'FONT_LICENSE/LOAD_DATA/SUCCESS':
      return loadData.reducer(state, action);

    case clearData.actionType:
      return clearData.reducer(state, action);

    case selectLicenseOption.actionType:
      return selectLicenseOption.reducer(state, action);

    case toggleFamilySelected.actionType:
      return toggleFamilySelected.reducer(state);

    case toggleSizeSelected.actionType:
      return toggleSizeSelected.reducer(state, action);

    case toggleWidthSelected.actionType:
      return toggleWidthSelected.reducer(state, action);

    case toggleFaceSelected.actionType:
      return toggleFaceSelected.reducer(state, action);

    case highlightPackage.actionType:
      return highlightPackage.reducer(state, action);

    case highlightFamily.actionType:
      return highlightFamily.reducer(state, action);
  }

  return state;
}

export function* saga() {
  yield takeEvery('FONT_LICENSE/LOAD_DATA', loadData.saga);
}
