import './CookieNotice.scss';

import React, { useCallback, useState } from 'react';
import Cookies from 'js-cookie';

import * as Core from 'components/core';

const CookieNotice: React.FC = () => {
  const [cookiesAgreed, setCookiesAgreed] = useState(() => {
    if (typeof window === 'undefined') {
      return true;
    }

    return Cookies.get('fj-cookies-accepted') === 'true';
  });

  const agree = useCallback(() => {
    Cookies.set('fj-cookies-accepted', 'true', { expires: 365 });

    setCookiesAgreed(true);
  }, []);

  if (cookiesAgreed) {
    return null;
  }

  return (
    <div className="CookieNotice-container">
      <div>
        We use cookies to measure website usage and improve your browsing
        experience. By continuing to use our site, you agree to our use of
        cookies.
      </div>

      <div className="CookieNotice-controls">
        <a href="/privacy">Learn more</a>

        <Core.Button
          type="button"
          secondary
          className="CookieNotice-button"
          onClick={agree}
        >
          I Accept
        </Core.Button>
      </div>
    </div>
  );
};

export default CookieNotice;
