import styles from './TeamMember.module.scss';

import classNames from 'classnames';

import React from 'react';

interface Props {
  imageSrc?: string;
  name: string;
  title: string;
  children: React.ReactNode;
}

class TeamMember extends React.PureComponent<Props> {
  render() {
    const { imageSrc, name, title, children } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.photo}>
          {imageSrc && <img className={styles.photoImage} src={imageSrc} />}
        </div>
        <div className={styles.text}>
          <div>
            <h2 className={classNames(styles.name, 'heading-2')}>
              {name}
              {' — '}
              <span className={styles.role}>{title}</span>
            </h2>
          </div>
          <div>{children}</div>
        </div>
      </div>
    );
  }
}

export default TeamMember;
