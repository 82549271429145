import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';

import * as Core from 'components/core';
const Show = React.lazy(() => import('./Show'));

interface Props extends RouteComponentProps {}

const Orders: React.FC<Props> = () => {
  return (
    <React.Suspense fallback={<Core.Loader />}>
      <Router primary={false} basepath="/order">
        <Show path=":number" />
      </Router>
    </React.Suspense>
  );
};

export default Orders;
