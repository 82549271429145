import './HomePageMasthead.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { Link } from 'components/core';

interface Props {
  features: {
    category: string | null;
    categoryUrl: string | null;
    caption: string;
    captionUrl: string | null;
    image1Url: {
      small: string;
      medium: string;
      large: string;
    };
    image2Url: {
      small: string;
      medium: string;
      large: string;
    };
    image3Url: {
      small: string;
      medium: string;
      large: string;
    };
  }[];
}

const HomePageMasthead: React.FC<Props> = (props) => {
  const { features } = props;

  const [activeSlide, setActiveSlide] = useState(0);
  const [animate, setAnimate] = useState(false);

  const interval = useRef<ReturnType<typeof setInterval>>();

  const startSlideShow = useCallback(() => {
    interval.current = setInterval(() => {
      setActiveSlide((currentSlide: number) => {
        if (currentSlide === features.length - 1) {
          return 0;
        }

        return currentSlide + 1;
      });
      setAnimate(true);
    }, 6000);
  }, [features.length]);

  useEffect(() => {
    startSlideShow();

    return () => {
      if (interval.current != null) {
        clearInterval(interval.current);
      }
    };
  }, [startSlideShow]);

  return (
    <div className="HomePageMasthead">
      {features.map((feature, index) => (
        <div
          className={classNames('HomePageMasthead-feature', {
            'HomePageMasthead-feature--active': index === activeSlide,
            'HomePageMasthead-feature--doNotAnimate':
              animate === false && index === 0,
            'HomePageMasthead-feature--neverAnimate': features.length === 1,
          })}
          key={index}
        >
          {feature.captionUrl ? (
            <Link
              to={feature.captionUrl}
              className="HomePageMasthead-feature-images"
            >
              <img
                src={feature.image1Url.large}
                srcSet={`${feature.image1Url.small} 400w, ${feature.image1Url.medium} 800w, ${feature.image1Url.large} 1200w`}
                sizes="(max-width: 600px) 100vw, 33vw"
                alt={feature.caption}
                className="HomePageMasthead-feature-image"
              />
              <img
                src={feature.image2Url.large}
                srcSet={`${feature.image2Url.small} 400w, ${feature.image2Url.medium} 800w, ${feature.image2Url.large} 1200w`}
                sizes="(max-width: 600px) 100vw, 33vw"
                alt={feature.caption}
                className="HomePageMasthead-feature-image"
              />
              <img
                src={feature.image3Url.large}
                srcSet={`${feature.image3Url.small} 400w, ${feature.image3Url.medium} 800w, ${feature.image3Url.large} 1200w`}
                sizes="(max-width: 600px) 100vw, 33vw"
                alt={feature.caption}
                className="HomePageMasthead-feature-image"
              />
            </Link>
          ) : (
            <div className="HomePageMasthead-feature-images">
              <img
                src={feature.image1Url.large}
                srcSet={`${feature.image1Url.small} 400w, ${feature.image1Url.medium} 800w, ${feature.image1Url.large} 1200w`}
                sizes="(max-width: 600px) 100vw, 33vw"
                alt={feature.caption}
                className="HomePageMasthead-feature-image"
              />
              <img
                src={feature.image2Url.large}
                srcSet={`${feature.image2Url.small} 400w, ${feature.image2Url.medium} 800w, ${feature.image2Url.large} 1200w`}
                sizes="(max-width: 600px) 100vw, 33vw"
                alt={feature.caption}
                className="HomePageMasthead-feature-image"
              />
              <img
                src={feature.image3Url.large}
                srcSet={`${feature.image3Url.small} 400w, ${feature.image3Url.medium} 800w, ${feature.image3Url.large} 1200w`}
                sizes="(max-width: 600px) 100vw, 33vw"
                alt={feature.caption}
                className="HomePageMasthead-feature-image"
              />
            </div>
          )}

          <div className="HomePageMasthead-feature-info">
            <div className="HomePageMasthead-feature-pages">
              {features.length > 1 &&
                features.map((_feature, subIndex) => (
                  <button
                    onClick={() => {
                      setActiveSlide(subIndex);
                      if (interval.current != null) {
                        clearInterval(interval.current);
                      }
                      startSlideShow();
                      setAnimate(true);
                    }}
                    key={subIndex}
                    className={classNames('HomePageMasthead-feature-page', {
                      'HomePageMasthead-feature-page--active':
                        index === subIndex,
                    })}
                  >
                    &bull;
                  </button>
                ))}
            </div>
            <div>
              {feature.category &&
                (feature.categoryUrl ? (
                  <span>
                    <Link to={feature.categoryUrl}>{feature.category}</Link> —{' '}
                  </span>
                ) : (
                  <span>{feature.category} —</span>
                ))}
              {feature.captionUrl ? (
                <Link to={feature.captionUrl}>{feature.caption}</Link>
              ) : (
                <span>{feature.caption}</span>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HomePageMasthead;
