import { State } from './index';

export type SetColors = {
  type: 'COLORS/SET';
  payload: {
    foregroundColor?: string;
    logoBackgroundColor?: string;
  };
};

export const actionType = 'COLORS/SET';

export function action(
  foregroundColor: string | undefined,
  logoBackgroundColor: string | undefined,
): SetColors {
  return {
    type: actionType,
    payload: {
      foregroundColor,
      logoBackgroundColor,
    },
  };
}

export function reducer(state: State, action: SetColors) {
  return {
    ...state,
    ...action.payload,
  };
}
