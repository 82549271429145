import './IndexRow.scss';

import React from 'react';
import { Link } from '@reach/router';

import * as Core from 'components/core';
import formatDate from 'utils/formatDate';

interface Props {
  article: {
    title: string;
    slug: string;
    publishedAt: string | null;
    excerpt: string | null;
    tags: string[];
    bannerImageUrl: string | null;
    longForm: boolean;
    author: string | null;
  };
}

const IndexRow: React.FC<Props> = (props) => {
  const { article } = props;

  return (
    <div className="ArticleIndexRow">
      <h2
        className={
          article.longForm
            ? 'ArticleIndexRow-headingLongForm'
            : 'ArticleIndexRow-heading'
        }
      >
        <Link to={`/blog/${article.slug}`}>{article.title}</Link>
      </h2>

      <Core.ContentWrapper narrow inline>
        {!article.longForm && article.bannerImageUrl && (
          <Link to={`/blog/${article.slug}`}>
            <img
              src={article.bannerImageUrl}
              className="ArticleIndexRow-image"
            />
          </Link>
        )}

        <Core.ColophonWrapper>
          {article.author && (
            <Core.ColophonColumn label="Author">
              {article.author}
            </Core.ColophonColumn>
          )}
          <Core.ColophonColumn label="Publish Date">
            {formatDate(article.publishedAt)}
          </Core.ColophonColumn>
          <Core.ColophonColumn label="Tags">
            <ul className="ArticleIndexRow-tagList">
              {article.tags.map((tag) => (
                <li className="ArticleIndexRow-tagList-item" key={tag}>
                  <Link to={`/blog?tag=${tag}`}>{tag}</Link>
                </li>
              ))}
            </ul>
          </Core.ColophonColumn>
        </Core.ColophonWrapper>

        {article.longForm && article.excerpt && (
          <>
            <p>{article.excerpt}</p>

            <div className="ArticleIndexRow-readPostLink">
              <Link to={`/blog/${article.slug}`}>Read Post &rarr;</Link>
            </div>
          </>
        )}
      </Core.ContentWrapper>
    </div>
  );
};

export default IndexRow;
