import './Single.scss';

import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Link, RouteComponentProps } from '@reach/router';

import * as Core from 'components/core';
import formatDate from 'utils/formatDate';
import ArticleContent from './ArticleContent';
import { ArticleQuery } from 'client/graphql/types/operations';

const ARTICLE = gql`
  query ArticleQuery($slug: String!) {
    article(slug: $slug) {
      id
      title
      slug
      publishedAt
      excerpt
      tags
      bannerImageUrl
      content
      longForm
      author
    }
  }
`;

interface Props extends RouteComponentProps {
  slug?: string;
}

export const Single: React.FC<Props> = (props) => {
  return (
    <Query<ArticleQuery>
      query={ARTICLE}
      variables={{
        slug: props.slug,
      }}
    >
      {({ loading, data }) => {
        if (loading) {
          return <Core.Loader />;
        }

        if (data == null || data.article == null) {
          return <Core.NotFound />;
        }

        const { article } = data;

        return (
          <Core.DocumentTitle title={article.title}>
            <div className="Single">
              <h1 className="Single-heading">{article.title}</h1>

              <Core.ColophonWrapper>
                {article.author && (
                  <Core.ColophonColumn label="Author">
                    {article.author}
                  </Core.ColophonColumn>
                )}
                <Core.ColophonColumn label="Publish Date">
                  {formatDate(article.publishedAt)}
                </Core.ColophonColumn>
                <Core.ColophonColumn label="Tags">
                  <ul className="Single-article-tagList">
                    {article.tags.map((tag) => (
                      <li className="Single-article-tagList-item" key={tag}>
                        <Link to={`/blog?tag=${tag}`}>{tag}</Link>
                      </li>
                    ))}
                  </ul>
                </Core.ColophonColumn>
              </Core.ColophonWrapper>

              <ArticleContent content={article.content} />
            </div>
          </Core.DocumentTitle>
        );
      }}
    </Query>
  );
};

Single.displayName = 'Blog.Single';

export default Single;
