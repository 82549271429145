import { takeEvery } from 'redux-saga/effects';

import * as append from './append';
import { Append } from './append';

import * as remove from './remove';
import { Remove } from './remove';

export type State = Array<{ id: string; content: string }>;
type Action = Append | Remove;

export const initialState: State = [];

export const actions = {
  append: append.action,
  remove: remove.action,
};

export function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case append.actionType:
      return append.reducer(state, action);

    case remove.actionType:
      return remove.reducer(state, action);
  }

  return state;
}

export function* saga() {
  yield takeEvery(append.actionType, append.saga);
}
