import update from 'immutability-helper';

import { State } from './index';
import { FontPackage } from './types';

export type HighlightPackage = {
  type: 'FONT_LICENSE/HIGHLIGHT_PACKAGE';
  payload: { fontPackage: FontPackage | null };
};

export const actionType = 'FONT_LICENSE/HIGHLIGHT_PACKAGE';

export function action(fontPackage: FontPackage | null): HighlightPackage {
  return {
    type: actionType,
    payload: { fontPackage },
  };
}

export function reducer(state: State, action: HighlightPackage) {
  const { fontPackage } = action.payload;

  return update(state, {
    license: { highlightedPackage: { $set: fontPackage } },
  });
}
