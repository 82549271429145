import update from 'immutability-helper';
import { without } from 'lodash-es';
import { State } from './index';

export const actionType = 'FONT_LICENSE/TOGGLE_FACE_SELECTED';

export type ToggleFaceSelected = {
  type: 'FONT_LICENSE/TOGGLE_FACE_SELECTED';
  payload: { faceId: number };
};

export function action(faceId: number): ToggleFaceSelected {
  return {
    type: actionType,
    payload: { faceId },
  };
}

export function reducer(state: State, action: ToggleFaceSelected) {
  const { faceId } = action.payload;
  const { faceIds } = state.license;

  if (faceIds.includes(faceId)) {
    return update(state, {
      license: { faceIds: { $set: without(faceIds, faceId) } },
    });
  } else {
    return update(state, {
      license: { faceIds: { $set: [...faceIds, faceId] } },
    });
  }
}
