import initialState from './initialState';
import { State } from './types';

export const actionType = 'FONT_LICENSE/CLEAR_DATA';

export type ClearData = {
  type: 'FONT_LICENSE/CLEAR_DATA';
};

export function action(): ClearData {
  return {
    type: 'FONT_LICENSE/CLEAR_DATA',
  };
}

export function reducer(state: State, action: ClearData): State {
  switch (action.type) {
    case 'FONT_LICENSE/CLEAR_DATA': {
      return initialState;
    }
  }

  return state;
}
