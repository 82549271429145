import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';
import { Elements } from 'react-stripe-elements';

import * as Core from 'components/core';
import Registration from './Registration';
import Licensing from './Licensing';

const Payment = React.lazy(() => import('./Payment'));

interface Props extends RouteComponentProps {}

const Checkout: React.FC<Props> = () => {
  return (
    <Elements>
      <React.Suspense fallback={<Core.Loader />}>
        <Router primary={false}>
          <Registration path="registration" />
          <Licensing path="licensing" />
          <Payment path="payment" />
        </Router>
      </React.Suspense>
    </Elements>
  );
};

export default Checkout;
