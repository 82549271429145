import React from 'react';
import ReactDOM from 'react-dom';

import * as Core from 'components/core';
import ZoomableImage from './ZoomableImage';

interface Props {
  content: string;
}

export class ArticleContent extends React.Component<Props> {
  static displayName = 'Blog.ArticleContent';

  articleNode = React.createRef<HTMLElement>();

  shouldComponentUpdate() {
    return false;
  }

  componentDidMount() {
    if (this.articleNode.current == null) {
      return;
    }

    const images =
      this.articleNode.current.querySelectorAll('img[data-zoom-src]');

    for (const image of Array.from(images)) {
      const alt = image.getAttribute('alt');
      const src = image.getAttribute('src');
      const zoomSrc = image.getAttribute('data-zoom-src');
      const container = document.createElement('div');

      if (image.parentNode != null) {
        image.parentNode.insertBefore(container, image);
        image.remove();

        ReactDOM.render(
          <ZoomableImage
            alt={alt || undefined}
            src={src || undefined}
            zoomSrc={zoomSrc || undefined}
          />,
          container,
        );
      }
    }
  }

  render() {
    const { content } = this.props;

    return (
      <Core.ContentWrapper narrow inline>
        <article ref={this.articleNode}>
          <Core.MarkdownContentWrapper content={content} />
        </article>
      </Core.ContentWrapper>
    );
  }
}

export default ArticleContent;
