import './About.scss';

import React from 'react';
import { Link, RouteComponentProps } from '@reach/router';

import * as Core from 'components/core';
import TeamMember from './TeamMember';

import tobiasHeadshotUrl from 'assets/images/headshot_tobias-frere-jones.jpg';
import christineHeadshotUrl from 'assets/images/headshot_christine-bateup.jpg';
import ninaHeadshotUrl from 'assets/images/headshot_nina-stossinger.jpg';
import fredHeadshotUrl from 'assets/images/headshot_fred-shallcrass.jpg';

interface Props extends RouteComponentProps {}

const About: React.FC<Props> = () => {
  return (
    <Core.DocumentTitle title="About">
      <Core.ContentWrapper>
        <Core.LargeTextIntro>
          <div className="About-intro-text">
            <span className="About-intro-text-emphasis">
              Frere-Jones Type is an award winning, independent type design
              practice in New York City, creating original typefaces for retail
              licensing and custom clients.
            </span>{' '}
            Combining technical expertise with historical perspective, we
            believe that type exists to solve problems, and beauty is always
            part of the solution.
          </div>
          <Core.ColophonWrapper>
            <Core.ColophonColumn label="Location">
              126 13th Street #4L-1
              <br />
              Brooklyn, NY 11215
            </Core.ColophonColumn>
            <Core.ColophonColumn label="Contact">
              <a
                className="About-link"
                href="mailto:info@%66rerej%6Fn%65s%2Ec%6Fm"
              >
                info@frerejones.com
              </a>
              <br />
              <a className="About-link" href="https://twitter.com/frerejones">
                Twitter
              </a>
              <br />
              <a
                className="About-link"
                href="https://www.instagram.com/frerejones/"
              >
                Instagram
              </a>
            </Core.ColophonColumn>
            <Core.ColophonColumn>
              <Link to="/events" className="About-link">
                Events
              </Link>
              <br />
              <Link to="/press" className="About-link">
                Press
              </Link>
            </Core.ColophonColumn>
          </Core.ColophonWrapper>
        </Core.LargeTextIntro>

        <TeamMember
          name="Tobias Frere-Jones"
          title="Founder and Design Director"
          imageSrc={tobiasHeadshotUrl}
        >
          <p>
            Over 25 years, Tobias Frere-Jones has established himself as one of
            the world’s leading typeface designers, creating some of the most
            widely used typefaces, including Interstate, Poynter Oldstyle,
            Whitney, Gotham, Surveyor, Tungsten and Retina.
          </p>
          <p>
            Tobias received a BFA in Graphic Design from the Rhode Island School
            of Design in 1992. He joined the faculty of the Yale University
            School of Art in 1996 and has lectured throughout the United States,
            Europe and Australia. His work is in the permanent collections of
            the Victoria & Albert Museum in London and the Museum of Modern Art
            in New York. He has received the Gerrit Noordzij Prijs, the AIGA
            Medal, and most recently Cooper Hewitt’s 2019 National Design Award
            for Communication Design, recognizing his contributions to
            typographic design, writing and education.
          </p>
        </TeamMember>

        <TeamMember
          name="Christine Bateup"
          title="Director of Business and Licensing"
          imageSrc={christineHeadshotUrl}
        >
          <p>
            Christine has had a roundabout and unexpected journey to the world
            of type. She spent a tremendous amount of time at university,
            receiving BA and LLB degrees from the University of Melbourne, an
            LLM from the London School of Economics and Political Science and a
            JSD (or doctorate in law) from New York University School of Law.
            Christine has practiced as an attorney in Australia and the United
            States, and now brings her skills to Frere-Jones Type where she
            handles licensing and business planning and development.
          </p>
        </TeamMember>

        <TeamMember
          name="Nina Stössinger"
          title="Senior Typeface Designer"
          imageSrc={ninaHeadshotUrl}
        >
          <p>
            Originally from Switzerland, Nina graduated in multi-media design
            from Burg Giebichenstein University of Art Halle/Germany, where she
            discovered her love for type. She went on to receive a CAS in Type
            Design from Zurich University of the Arts, and an MA in Type and
            Media from the Royal Academy of Art in The Hague. Nina joined
            Frere-Jones Type in 2016 after working as an independent graphic and
            type designer. Nina enjoys code and words, history and new ideas;
            she has spoken at numerous conferences and events, and has had her
            work exhibited internationally. Nina previously served on the Board
            of Directors of the Type Directors Club where she chaired the 22nd
            TDC Typeface Design Competition. She also teaches type design at
            Yale School of Art.
          </p>
        </TeamMember>

        <TeamMember
          name="Fred Shallcrass"
          title="Typeface Designer"
          imageSrc={fredHeadshotUrl}
        >
          <p>
            Fred grew up in the modest yet active publishing scene of New
            Zealand where he designed magazines, lettering and type for various
            editorial clients. After moving to New York in 2015 he attended the
            Type@Cooper Extended program where he is now a Teaching Assistant.
            Before joining Frere-Jones Type, Fred had been using breaks from
            editorial design to work on brand, identity and type projects for
            clients including USA TODAY, Te Papa and The Wellington City
            Council.
          </p>
        </TeamMember>

        <div className="About-credit">
          Photos: <a href="http://www.grahammacindoe.com">Graham MacIndoe</a>
        </div>

        <Core.ScrollToElement />
      </Core.ContentWrapper>
    </Core.DocumentTitle>
  );
};

export default About;
