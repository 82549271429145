import React, { useEffect } from 'react';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Link, Redirect, RouteComponentProps } from '@reach/router';
import { Formik } from 'formik';

import * as Core from 'components/core';
import * as redirectToPath from 'utils/redirectToPath';
import {
  CreateSessionMutation,
  CreateSessionMutationVariables,
} from 'client/graphql/types/operations';

import {
  CurrentUserQuery,
  USER_ATTRIBUTES,
  updateCache,
  CURRENT_USER_QUERY,
} from 'components/queries/CurrentUserQuery';
import { useDispatch } from 'react-redux';
import { actions } from 'modules/notifications';

const CREATE_SESSION_MUTATION = gql`
  mutation CreateSessionMutation($input: CreateSessionInput!) {
    createSession(input: $input) {
      user {
        ...UserAttributes
      }

      errors
    }
  }

  ${USER_ATTRIBUTES}
`;

interface Props extends RouteComponentProps {}

export const Session: React.FC<Props> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const redirectTo = new URLSearchParams(location.search).get('redirectTo');

    if (redirectTo != null) {
      redirectToPath.set(redirectTo);
    }
  }, []);

  return (
    <Core.DocumentTitle title="Sign In">
      <Core.ContentWrapper>
        <Query<CurrentUserQuery> query={CURRENT_USER_QUERY}>
          {({ loading, data }) => {
            if (loading) {
              return <Core.Loader />;
            }

            if (data != null && data.currentUser != null) {
              return <Redirect noThrow to={redirectToPath.get('/account')} />;
            }

            return (
              <>
                <Mutation<CreateSessionMutation, CreateSessionMutationVariables>
                  mutation={CREATE_SESSION_MUTATION}
                  update={(cache, { data }) => {
                    if (
                      data == null ||
                      data.createSession == null ||
                      data.createSession.user == null
                    ) {
                      return;
                    }

                    try {
                      updateCache(cache, data.createSession.user);
                    } catch (error) {
                      // TODO: Handle error
                    }
                  }}
                  onCompleted={(data) => {
                    if (
                      data != null &&
                      data.createSession != null &&
                      data.createSession.user != null
                    ) {
                      dispatch(actions.append('Successfully signed in!'));
                    }
                  }}
                >
                  {(createSession, { data }) => {
                    return (
                      <Formik
                        initialValues={{
                          email: '',
                          password: '',
                        }}
                        onSubmit={(values) => {
                          createSession({
                            variables: {
                              input: {
                                email: values.email,
                                password: values.password,
                              },
                            },
                          });
                        }}
                        render={({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            {data != null &&
                              data.createSession != null &&
                              data.createSession.errors != null &&
                              data.createSession.errors.map((error, index) => (
                                <div key={index} className="error">
                                  {error}
                                </div>
                              ))}

                            <Core.InputFormGroup
                              label="Email"
                              type="email"
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />

                            <Core.InputFormGroup
                              label="Password"
                              type="password"
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />

                            <Core.Button className="auth-button" type="submit">
                              Sign In
                            </Core.Button>
                          </form>
                        )}
                      />
                    );
                  }}
                </Mutation>

                <div>
                  <Link to="/registration">Create an account</Link>
                </div>

                <div>
                  <Link to="/password-reset">Forgot your password?</Link>
                </div>
              </>
            );
          }}
        </Query>
      </Core.ContentWrapper>
    </Core.DocumentTitle>
  );
};

export default Session;
