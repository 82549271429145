import React from 'react';
import { RouteComponentProps } from '@reach/router';

import * as Core from 'components/core';

interface Props extends RouteComponentProps {}

const WorkWithUs: React.FC<Props> = () => {
  return (
    <Core.DocumentTitle title="Work with Us">
      <Core.ContentWrapper narrow>
        <Core.PageContent slug="work-with-us" />
      </Core.ContentWrapper>
    </Core.DocumentTitle>
  );
};

export default WorkWithUs;
