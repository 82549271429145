import React from 'react';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Redirect, navigate, RouteComponentProps } from '@reach/router';

import * as Core from 'components/core';
import {
  CurrentOrderQuery,
  CURRENT_ORDER_QUERY,
} from 'components/queries/CurrentOrderQuery';
import { LicensePagesQuery } from 'client/graphql/types/operations';
import {
  TransitionCurrentOrderMutation,
  TransitionCurrentOrderMutationVariables,
} from 'client/graphql/types/operations';

const LICENSE_PAGES_QUERY = gql`
  query LicensePagesQuery {
    desktop: page(slug: "licensing/desktop") {
      content
    }

    web: page(slug: "licensing/web") {
      content
    }

    mobile: page(slug: "licensing/mobile") {
      content
    }
  }
`;

const TRANSITION_CURRENT_ORDER_MUTATION = gql`
  mutation TransitionCurrentOrderMutation(
    $input: TransitionCurrentOrderInput!
  ) {
    transitionCurrentOrder(input: $input) {
      order {
        id
        state
      }
    }
  }
`;

interface Props extends RouteComponentProps {}

const CheckoutLicensing: React.FC<Props> = () => {
  return (
    <Query<CurrentOrderQuery> query={CURRENT_ORDER_QUERY}>
      {({ data, loading }) => {
        if (loading) {
          return null;
        }

        if (data == null || data.currentOrder == null) {
          return <Redirect noThrow to="/cart" />;
        }

        const { currentOrder } = data;

        return (
          <Core.DocumentTitle title="License Agreement">
            <Core.ContentWrapper narrow>
              <h1 className="heading-1">Licensing</h1>

              <p>
                Your use of these fonts is subject to the following terms and
                conditions. Please carefully review these documents before
                proceeding with your purchase.
              </p>

              <Query<LicensePagesQuery> query={LICENSE_PAGES_QUERY}>
                {({ loading, data }) => {
                  if (loading) {
                    return <Core.Loader />;
                  }

                  const licenseTypes = currentOrder.fontLicenses.map(
                    (license) => license.option.kind,
                  );

                  if (
                    data == null ||
                    data.desktop == null ||
                    data.web == null ||
                    data.mobile == null
                  ) {
                    throw new Error('Missing license content');
                  }

                  return (
                    <>
                      {licenseTypes.includes('desktop') && (
                        <Core.MarkdownContentWrapper
                          content={data.desktop.content}
                        />
                      )}

                      {licenseTypes.includes('web') && (
                        <Core.MarkdownContentWrapper
                          content={data.web.content}
                        />
                      )}

                      {licenseTypes.includes('mobile') && (
                        <Core.MarkdownContentWrapper
                          content={data.mobile.content}
                        />
                      )}

                      <Mutation<
                        TransitionCurrentOrderMutation,
                        TransitionCurrentOrderMutationVariables
                      >
                        mutation={TRANSITION_CURRENT_ORDER_MUTATION}
                        onCompleted={() => navigate('/checkout/payment')}
                      >
                        {(transitionCurrentOrder, { loading }) => {
                          return (
                            <Core.Button
                              className="checkout-button"
                              disabled={loading}
                              onClick={() => {
                                transitionCurrentOrder({
                                  variables: { input: { nextStep: 'payment' } },
                                });
                              }}
                            >
                              I Accept
                            </Core.Button>
                          );
                        }}
                      </Mutation>
                    </>
                  );
                }}
              </Query>
            </Core.ContentWrapper>
          </Core.DocumentTitle>
        );
      }}
    </Query>
  );
};

export default CheckoutLicensing;
