import './ZoomableImage.scss';

import React from 'react';
import classNames from 'classnames';

interface Props {
  alt?: string;
  src?: string;
  zoomSrc?: string;
}

interface State {
  zoomed: boolean;
}

class ZoomableImage extends React.PureComponent<Props, State> {
  state = { zoomed: false };

  render() {
    const { alt, src, zoomSrc } = this.props;
    const { zoomed } = this.state;

    return (
      <div
        className={classNames('ZoomableImage', {
          'ZoomableImage--zoomed': zoomed,
        })}
      >
        <img
          alt={alt}
          src={zoomed ? zoomSrc : src}
          className="ZoomableImage-image"
          onClick={() => {
            this.setState((state) => ({ zoomed: !state.zoomed }));
          }}
        />
      </div>
    );
  }
}

export default ZoomableImage;
