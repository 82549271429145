import update from 'immutability-helper';
import { difference, union } from 'lodash-es';

import { State } from './index';

export type ToggleSizeSelected = {
  type: 'FONT_LICENSE/TOGGLE_SIZE_SELECTED';
  payload: { size: string };
};

export const actionType = 'FONT_LICENSE/TOGGLE_SIZE_SELECTED';

export function action(size: string): ToggleSizeSelected {
  return {
    type: actionType,
    payload: { size },
  };
}

export function reducer(state: State, action: ToggleSizeSelected) {
  const { license, family } = state;
  const { size } = action.payload;

  if (family == null) {
    return state;
  }

  const sizeFaceIds = family.faces
    .filter((face) => face.size === size)
    .map((face) => face.id);

  const sizeSelected = sizeFaceIds.every((id) => license.faceIds.includes(id));

  const { faceIds } = state.license;

  return update(state, {
    license: {
      faceIds: {
        $set: sizeSelected
          ? difference(faceIds, sizeFaceIds)
          : union(faceIds, sizeFaceIds),
      },
    },
  });
}
