import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { sortBy } from 'lodash-es';

import * as Core from 'components/core';
import { CountriesQuery } from 'client/graphql/types/operations';

const COUNTRIES_QUERY = gql`
  query CountriesQuery {
    countries {
      name
      alpha2
    }
  }
`;

interface Props {
  children(countries: CountriesQuery['countries']): React.ReactNode;
}

export const CountriesContainer: React.FC<Props> = (props) => {
  return (
    <Query<CountriesQuery> query={COUNTRIES_QUERY}>
      {({ loading, data }) => {
        if (loading || data == null) {
          return <Core.Loader />;
        }

        const countries = sortBy(data.countries, [
          (country) => {
            const index = ['US', 'CA', 'GB'].indexOf(country.alpha2);
            return index === -1 ? 10 : index;
          },
          'name',
        ]);

        return <>{props.children(countries)}</>;
      }}
    </Query>
  );
};

export default CountriesContainer;
