import update from 'immutability-helper';

import { State } from './index';

export type ToggleFamilySelected = {
  type: 'FONT_LICENSE/TOGGLE_FAMILY_SELECTED';
};

export const actionType = 'FONT_LICENSE/TOGGLE_FAMILY_SELECTED';

export function action(): ToggleFamilySelected {
  return {
    type: actionType,
  };
}

export function reducer(state: State) {
  const { license, family } = state;

  if (family == null) {
    return state;
  }

  const familyFaceIds = family.faces.map((face) => face.id);

  const familySelected = familyFaceIds.every((id) =>
    license.faceIds.includes(id),
  );

  return update(state, {
    license: { faceIds: { $set: familySelected ? [] : familyFaceIds } },
  });
}
