import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { sortBy } from 'lodash-es';

import * as Core from 'components/core';
import { FaqQuery } from 'client/graphql/types/operations';
import { RouteComponentProps } from '@reach/router';

const FAQ_QUERY = gql`
  query FAQQuery {
    faqGroups {
      id
      slug
      title
      position

      faqs {
        id
        slug
        question
        content
        position
      }
    }
  }
`;

interface Props extends RouteComponentProps {}

const PagesFrequentlyAskedQuestions: React.FC<Props> = () => {
  return (
    <Core.DocumentTitle title="FAQ">
      <Core.ContentWrapper narrow>
        <Query<FaqQuery> query={FAQ_QUERY}>
          {({ loading, data }) => {
            if (loading) {
              return <Core.Loader />;
            }

            if (data == null) {
              return null;
            }

            return (
              <div>
                <h1 className="heading-1">Frequently Asked Questions</h1>

                <ul className="FrequentlyAskedQuestions-nav">
                  {sortBy(data.faqGroups, 'position').map((group) => (
                    <li
                      key={group.id}
                      className="FrequentlyAskedQuestions-nav-listItem"
                    >
                      <a href={`#${group.slug}`}>{group.title}</a>
                    </li>
                  ))}
                </ul>

                {sortBy(data.faqGroups, 'position').map((group) => (
                  <div
                    key={group.id}
                    className="FrequentlyAskedQuestions-group"
                  >
                    <h2
                      className="heading-2 FrequentlyAskedQuestions-groupHeading"
                      id={group.slug}
                    >
                      {group.title}
                    </h2>

                    {sortBy(group.faqs, 'position').map((faq) => (
                      <React.Fragment key={faq.id}>
                        <h3 id={faq.slug} className="heading-2">
                          {faq.question}
                        </h3>

                        <Core.MarkdownContentWrapper content={faq.content} />
                      </React.Fragment>
                    ))}
                  </div>
                ))}

                <Core.ScrollToElement />
              </div>
            );
          }}
        </Query>
      </Core.ContentWrapper>
    </Core.DocumentTitle>
  );
};

export default PagesFrequentlyAskedQuestions;
