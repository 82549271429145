import React from 'react';
import { Link } from '@reach/router';

import ContentWrapper from './ContentWrapper';

interface Props {
  error?: Error;
}

/*
 * This is rendered if an Error is thrown within either
 * <FallbackErrorBoundary /> or <BugsnagErrorBoundary />.
 */
const ErrorBoundaryMessage: React.FC<Props> = (props) => {
  if (props.error == null) {
    return null;
  }

  return (
    <ContentWrapper>
      <div className="ErrorBoundaryMessage">
        <h1 className="heading-1">An error has occurred.</h1>

        <p>We’re sorry, but something has gone wrong.</p>

        <p>
          Head back <Link to="/">home</Link>.
        </p>
      </div>
    </ContentWrapper>
  );
};

export default ErrorBoundaryMessage;
