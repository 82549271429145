import React from 'react';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Redirect } from '@reach/router';

import * as Core from 'components/core';
import * as redirectToPath from 'utils/redirectToPath';
import {
  CurrentUserQuery,
  CURRENT_USER_QUERY,
  USER_ATTRIBUTES,
  updateCache,
} from 'components/queries/CurrentUserQuery';
import {
  CreateUserMutation,
  CreateUserMutationVariables,
} from 'client/graphql/types/operations';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { actions } from 'modules/notifications';

const CREATE_USER_MUTATION = gql`
  mutation CreateUserMutation($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        ...UserAttributes
      }

      errors
    }
  }

  ${USER_ATTRIBUTES}
`;

interface Props {
  redirectTo?: string;
}

export const RegistrationForm: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  return (
    <Query<CurrentUserQuery> query={CURRENT_USER_QUERY}>
      {({ loading, data }) => {
        if (loading) {
          return <Core.Loader />;
        }

        if (data != null && data.currentUser != null) {
          return (
            <Redirect
              noThrow
              to={props.redirectTo || redirectToPath.get('/account')}
            />
          );
        }

        return (
          <Mutation<CreateUserMutation, CreateUserMutationVariables>
            mutation={CREATE_USER_MUTATION}
            update={(cache, { data }) => {
              if (
                data == null ||
                data.createUser == null ||
                data.createUser.user == null
              ) {
                return;
              }

              dispatch(actions.append('Account created successfully!'));

              try {
                updateCache(cache, data.createUser.user);
              } catch (error) {
                // TODO: Handle error
              }
            }}
          >
            {(createUser, { data, loading }) => {
              return (
                <Formik
                  initialValues={{
                    email: '',
                    password: '',
                    passwordConfirmation: '',
                    subscribeToNewsletter: false,
                  }}
                  onSubmit={(input) => {
                    createUser({ variables: { input } });
                  }}
                  render={({ handleSubmit, handleChange, handleBlur }) => (
                    <form onSubmit={handleSubmit}>
                      {data != null &&
                        data.createUser != null &&
                        data.createUser.errors != null &&
                        data.createUser.errors.map((error, index) => (
                          <div key={index} className="error">
                            {error}
                          </div>
                        ))}

                      <Core.InputFormGroup
                        name="email"
                        label="Email"
                        type="email"
                        autoComplete="username email"
                        required
                        disabled={loading}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <Core.InputFormGroup
                        name="password"
                        label="Password"
                        type="password"
                        autoComplete="new-password"
                        required
                        disabled={loading}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <Core.InputFormGroup
                        name="passwordConfirmation"
                        label="Password Confirmation"
                        type="password"
                        autoComplete="new-password"
                        required
                        disabled={loading}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <Core.CheckboxField
                        name="subscribeToNewsletter"
                        disabled={loading}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        Yes I would like to receive information about new font
                        releases from Frere-Jones Type!
                      </Core.CheckboxField>

                      <Core.Button
                        className="auth-button"
                        type="submit"
                        disabled={loading}
                      >
                        Sign Up
                      </Core.Button>
                    </form>
                  )}
                />
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  );
};

export default RegistrationForm;
